export default [
  {
    chainId: 1,
    address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    name: "Ether",
    decimals: 18,
    symbol: "ETH",
    logoURI:
      "https://images.dodoex.io/prKmKP8yDTuPMHTCZ_DcxG8BqsuHNO8w5KDmJWmPodg/rs:fit:96:96:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vOUVaWU1ER2ZVN3g2N3ZBZThqWkUxZzA0RExUaFhaV0JIb09wZFhpeXhHRS9hSFIwY0hNNkx5OWpaRzR0YldWa2FXRXVaRzlrYjJWNExtbHZMMlZ5WXpJd0wyaDBkSEJ6WDNNeVgyTnZhVzV0WVhKclpYUmpZWEJmWTI5dFgzTjBZWFJwWTE5cGJXZGZZMjlwYm5OZk5qUjROalJmTVRBeU4xOHhNekkyTjJGbE5EY3dMbkJ1WncucG5n.webp",
  },
  {
    chainId: 1,
    address: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
    name: "USD Coin",
    decimals: 6,
    symbol: "USDC",
    logoURI:
      "https://images.dodoex.io/sQ5dF3FkjjQUsmfqFFE5cKq-cthh4u0wUooBE5Epf-k/rs:fit:96:96:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vTDlEVElLa2dONG5mRkNTSF9GMUdXU3JiZkJDa2JZRTkwbmFDS0dIWnRsby9hSFIwY0hNNkx5OWpaRzR0YldWa2FXRXVaRzlrYjJWNExtbHZMM1Z6WkdOZlpXVTFNbUV4WldReVlpOTFjMlJqWDJWbE5USmhNV1ZrTW1JdWNHNW4ucG5n.webp",
  },
  {
    chainId: 1,
    address: "0x4Fabb145d64652a948d72533023f6E7A623C7C53",
    name: "Binance USD",
    decimals: 18,
    symbol: "BUSD",
    logoURI:
      "https://images.dodoex.io/xZmadzpVOnpuq2jNG_5EGKeqMET0LU_gmfnp4VxkcxI/rs:fit:96:96:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vSHQwWXZKMGNnU0lGTFM0aHExTV9jOXVITV9fMHpXaHBKSGVOQU5neTBuby9hSFIwY0hNNkx5OWpaRzR0YldWa2FXRXVaRzlrYjJWNExtbHZMMkoxYzJSZk4ySTJOalJpWWpReVpDOWlkWE5rWHpkaU5qWTBZbUkwTW1RdWNHNW4ucG5n.webp",
  },
  {
    chainId: 1,
    address: "0x6b175474e89094c44da98b954eedeac495271d0f",
    name: "Dai Stablecoin",
    decimals: 18,
    symbol: "DAI",
    logoURI:
      "https://images.dodoex.io/h2lbKBfBJ2LNSTNCuwjsBkshT4eXzDDmAV0lhWS0llA/rs:fit:96:96:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vUnVJNWt2eHBwalI4TTZsenFZZVAyTTB4eVFMUGJ0S1hONDdWVTN1dUszZy9hSFIwY0hNNkx5OWpaRzR0YldWa2FXRXVaRzlrYjJWNExtbHZMMlZ5WXpJd0wyeHZaMjlmWmpBNU5qUmpPVFUxT1M1d2JtYy5wbmc.webp",
  },
  {
    chainId: 1,
    address: "0x43dfc4159d86f3a37a5a4b3d4580b888ad7d4ddd",
    name: "DODO bird",
    decimals: 18,
    symbol: "DODO",
    logoURI:
      "https://images.dodoex.io/PGIGjN4ix9pInoESO_mCmajbMpygApWZyGKaE6vyJFk/rs:fit:96:96:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8veFJDQ0hnQXBuaENRTFZNUXAwdTdwd0pSQzIxRHJ4cFY0b2Z0bndaYnRmMC9hSFIwY0hNNkx5OWpaRzR0YldWa2FXRXVaRzlrYjJWNExtbHZMMFJQUkU5Zk5UaGxZelEyTlRFelpTOUVUMFJQWHpVNFpXTTBOalV4TTJVdWMzWm4uc3Zn.webp",
  },
  {
    chainId: 1,
    address: "0xdac17f958d2ee523a2206206994597c13d831ec7",
    name: "Tether USD",
    decimals: 6,
    symbol: "USDT",
    logoURI:
      "https://images.dodoex.io/MGklrG5AImwUllGWDPMAHY0qLfdJQn4bj3iPFWSeohA/rs:fit:96:96:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vUEM3a3JPb252bWl4U3VRaFEwY29pVy1sczFYN25WS1lLNVluekQ2R2lxRS9hSFIwY0hNNkx5OWpaRzR0YldWa2FXRXVaRzlrYjJWNExtbHZMMVZUUkZSZlpUaGlOekZpTldZeU9TOVZVMFJVWDJVNFlqY3hZalZtTWprdWNHNW4ucG5n.webp",
  },
  {
    chainId: 1,
    address: "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
    name: "Wrapped BTC",
    decimals: 8,
    symbol: "WBTC",
    logoURI:
      "https://images.dodoex.io/n4n7ccBom2tgcZNN0jBv9YiTq5qGMjX6PHO0YGlpzZo/rs:fit:96:96:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vMElrSkQzVEc2SkFQdG1DV24tNnhfa0ozS3lXYXlVVHRhZ19PQmF0TFEwRS9hSFIwY0hNNkx5OWpaRzR0YldWa2FXRXVaRzlrYjJWNExtbHZMMWRDVkVOZmFXTnZibDgzWW1JelpUQTRaak14TDFkQ1ZFTmZhV052Ymw4M1ltSXpaVEE0WmpNeExuQnVady5wbmc.webp",
  },
  {
    chainId: 1,
    address: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    name: "Wrapped Ether",
    decimals: 18,
    symbol: "WETH",
    logoURI:
      "https://images.dodoex.io/mI69kq-S-tNT1W57qYizPd5r2WNFzpAq5y6NhOcrTkY/rs:fit:96:96:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vVEZhSVNLVVZIWThLTVhyTkVmZDRXRUJmMGJoSHRweHY3Q1hDT0dKbGY2by9hSFIwY0hNNkx5OWpaRzR0YldWa2FXRXVaRzlrYjJWNExtbHZMMlZ5WXpJd0wyeHZaMjlmT0dVeFpEaG1PVEEyWlM1d2JtYy5wbmc.webp",
  },
  {
    chainId: 5,
    symbol: "USDC",
    address: "0xC4106029d03c33731Ca01Ba59b5A6368c660E596",
    name: "USDC",
    decimals: 6,
    logoURI: "",
  },
  {
    chainId: 5,
    symbol: "USDT",
    address: "0xed8a325D87D3b6edED04FB992A1482231647EE5e",
    name: "USDT Token",
    decimals: 6,
    logoURI: "",
  },
  {
    chainId: 5,
    symbol: "WETH",
    address: "0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6",
    name: "Wrapped Ether",
    decimals: 18,
    logoURI: "",
  },
  {
    chainId: 5,
    symbol: "ETH",
    address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    name: "Ether",
    decimals: 18,
    logoURI: "",
  },
  {
    chainId: 11155111,
    address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    name: "Ether",
    decimals: 18,
    symbol: "ETH",
    logoURI:
      "https://images.dodoex.io/prKmKP8yDTuPMHTCZ_DcxG8BqsuHNO8w5KDmJWmPodg/rs:fit:96:96:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vOUVaWU1ER2ZVN3g2N3ZBZThqWkUxZzA0RExUaFhaV0JIb09wZFhpeXhHRS9hSFIwY0hNNkx5OWpaRzR0YldWa2FXRXVaRzlrYjJWNExtbHZMMlZ5WXpJd0wyaDBkSEJ6WDNNeVgyTnZhVzV0WVhKclpYUmpZWEJmWTI5dFgzTjBZWFJwWTE5cGJXZGZZMjlwYm5OZk5qUjROalJmTVRBeU4xOHhNekkyTjJGbE5EY3dMbkJ1WncucG5n.webp",
  },
  {
    chainId: 11155111,
    address: "0x7B07164ecFaF0F0D85DFC062Bc205a4674c75Aa0",
    name: "Wrapped Ether",
    decimals: 18,
    symbol: "WETH",
    logoURI:
      "https://images.dodoex.io/mI69kq-S-tNT1W57qYizPd5r2WNFzpAq5y6NhOcrTkY/rs:fit:96:96:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vVEZhSVNLVVZIWThLTVhyTkVmZDRXRUJmMGJoSHRweHY3Q1hDT0dKbGY2by9hSFIwY0hNNkx5OWpaRzR0YldWa2FXRXVaRzlrYjJWNExtbHZMMlZ5WXpJd0wyeHZaMjlmT0dVeFpEaG1PVEEyWlM1d2JtYy5wbmc.webp",
  },
  {
    chainId: 56,
    address: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
    name: "BUSD Token",
    decimals: 18,
    symbol: "BUSD",
    logoURI:
      "https://images.dodoex.io/BvxyffA5gsLB4bLmlOVkfu7AVami78-VcW_6_i8I6kc/rs:fit:96:96:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vbC1Sa25meFB3ZXlCOGVYSWJ2b04wdHczLTlzckZOckxfRHdTTnRLSHVRRS9hSFIwY0hNNkx5OWpaRzR0YldWa2FXRXVaRzlrYjJWNExtbHZMMlZ5WXpJd0wyaDBkSEJ6WDJOa2JsOXRaV1JwWVY5a2IyUnZaWGhmYVc5ZlluVnpaRjgyTXpsbFlqZzNZV0V4WDJKMWMyUmZOak01WldJNE4yRmhNVjlsT1dVMVlXRTBOemxtTG5OMlp3LnN2Zw.webp",
  },
  {
    chainId: 56,
    address: "0x55d398326f99059ff775485246999027b3197955",
    name: "Tether USD",
    decimals: 18,
    symbol: "USDT",
    logoURI:
      "https://images.dodoex.io/QOl7CvP_WSmTUOMthbxQsNKjDj0s5kSjup69dW_-UeA/rs:fit:96:96:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vTjdQU3RXV2ZGaGNTVzM1QlE2TEJIaF9keVROUEJqa1RLTXRwTll5cEhSWS9hSFIwY0hNNkx5OWpaRzR0YldWa2FXRXVaRzlrYjJWNExtbHZMMlZ5WXpJd0wyaDBkSEJ6WDJOa2JsOXRaV1JwWVY5a2IyUnZaWGhmYVc5ZmRYTmtkRjh4TlRZME1XWmpaRFF3WDNWelpIUmZNVFUyTkRGbVkyUTBNRjlqWmpJMVpUYzVZVE5qTG5OMlp3LnN2Zw.webp",
  },
  {
    chainId: 56,
    address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    name: "BNB",
    decimals: 18,
    symbol: "BNB",
    logoURI:
      "https://images.dodoex.io/BWNSMMqu68bSFJsr9vsiCV09IonH3yuAkR9gQxaipBw/rs:fit:96:96:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vZDhvazF4akd3bU9GUXUtMVdzNEVRLVBNRnJYMW9DLXlmYTc3OGJUUkx0US9hSFIwY0hNNkx5OWpaRzR0YldWa2FXRXVaRzlrYjJWNExtbHZMMEpPUWw5alpEUTVNalE0TXpJMkwwSk9RbDlqWkRRNU1qUTRNekkyTG5CdVp3LnBuZw.webp",
  },
  {
    chainId: 56,
    address: "0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c",
    name: "BTCB Token",
    decimals: 18,
    symbol: "BTCB",
    logoURI:
      "https://images.dodoex.io/fZG0z_RZ1U73_LuuC3RGrrZvNR-bgrCUjkwvaro-PCY/rs:fit:96:96:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vZ1NXN0RuYWNkMnc2YWxSQnFmcENqTHNDZWJhc2VtQTc0dDJlTEdDZjhfNC9hSFIwY0hNNkx5OWpaRzR0YldWa2FXRXVaRzlrYjJWNExtbHZMMlZ5WXpJd0wyeHZaMjlmWmpJNVlXRmpZamxoTVM1d2JtYy5wbmc.webp",
  },
  {
    chainId: 56,
    address: "0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3",
    name: "Dai Token",
    decimals: 18,
    symbol: "DAI",
    logoURI:
      "https://images.dodoex.io/bhz9Vtma8hPb_-GnhwqQkhfTQ5PwKcR1Qbzn7aBKlfs/rs:fit:96:96:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vZW04OHB4YXNDdEg0Nl9BcjhVandzZWR2SFBFUUtjVWVkc0FmV2lvenB2Yy9hSFIwY0hNNkx5OWpaRzR0YldWa2FXRXVaRzlrYjJWNExtbHZMMlZ5WXpJd0wyeHZaMjlmWVRoaFptSmxaRFppWkM1d2JtYy5wbmc.webp",
  },
  {
    chainId: 56,
    address: "0x67ee3Cb086F8a16f34beE3ca72FAD36F7Db929e2",
    name: "DODO bird",
    decimals: 18,
    symbol: "DODO",
    logoURI:
      "https://images.dodoex.io/PGIGjN4ix9pInoESO_mCmajbMpygApWZyGKaE6vyJFk/rs:fit:96:96:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8veFJDQ0hnQXBuaENRTFZNUXAwdTdwd0pSQzIxRHJ4cFY0b2Z0bndaYnRmMC9hSFIwY0hNNkx5OWpaRzR0YldWa2FXRXVaRzlrYjJWNExtbHZMMFJQUkU5Zk5UaGxZelEyTlRFelpTOUVUMFJQWHpVNFpXTTBOalV4TTJVdWMzWm4uc3Zn.webp",
  },
  {
    chainId: 56,
    address: "0x2170ed0880ac9a755fd29b2688956bd959f933f8",
    name: "Ethereum Token",
    decimals: 18,
    symbol: "ETH",
    logoURI:
      "https://images.dodoex.io/KWYmKxU_OCS1PmzuEO0xjdr1Yn08SjGBkyqSI8hxlpE/rs:fit:96:96:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vdkkyZF9rdFdyUzQyM0FqZlBLbkxRYloyRGVzTkpacmNTSlZNb1dYRnkyOC9hSFIwY0hNNkx5OWpaRzR0YldWa2FXRXVaRzlrYjJWNExtbHZMMWRGVkVoZmFXTnZibDh6TVRjd1pqTTBORGcxTDFkRlZFaGZhV052Ymw4ek1UY3daak0wTkRnMUxuQnVady5wbmc.webp",
  },
  {
    chainId: 56,
    address: "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d",
    name: "Binance-Peg USD Coin",
    decimals: 18,
    symbol: "USDC",
    logoURI:
      "https://images.dodoex.io/sQ5dF3FkjjQUsmfqFFE5cKq-cthh4u0wUooBE5Epf-k/rs:fit:96:96:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vTDlEVElLa2dONG5mRkNTSF9GMUdXU3JiZkJDa2JZRTkwbmFDS0dIWnRsby9hSFIwY0hNNkx5OWpaRzR0YldWa2FXRXVaRzlrYjJWNExtbHZMM1Z6WkdOZlpXVTFNbUV4WldReVlpOTFjMlJqWDJWbE5USmhNV1ZrTW1JdWNHNW4ucG5n.webp",
  },
  {
    chainId: 56,
    address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    name: "Wrapped BNB",
    decimals: 18,
    symbol: "WBNB",
    logoURI:
      "https://images.dodoex.io/-Pua5rc9k9iW4yuF5ajLVKzMkRhBq06D8HiEZ5gjezA/rs:fit:96:96:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vMDZOWTdBTmdOYWdFOHpXYUMtX2IzYXVPOUxsTEp6QlpLTUR3VlM5eW9DQS9hSFIwY0hNNkx5OWpaRzR0YldWa2FXRXVaRzlrYjJWNExtbHZMMlZ5WXpJd0wyeHZaMjlmT1dRellXTmtZMlEzTnk1d2JtYy5wbmc.webp",
  },
  {
    chainId: 137,
    address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    name: "MATIC",
    decimals: 18,
    symbol: "MATIC",
    logoURI:
      "https://images.dodoex.io/cQFrEB6yGIqMkh_6Pie8VR3kIStL58IS-y8FbroHVwA/rs:fit:96:96:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vc2VNUUk2dkpoaXljeldrczJaWWdGdk8zSEoxSWFIa053LXZYeXFneER4US9hSFIwY0hNNkx5OWpaRzR0YldWa2FXRXVaRzlrYjJWNExtbHZMekZmTWpkbE56TXlNRE13TWk4eFh6STNaVGN6TWpBek1ESXVjRzVuLnBuZw.webp",
  },
  {
    chainId: 137,
    address: "0xe4bf2864ebec7b7fdf6eeca9bacae7cdfdaffe78",
    name: "DODO bird",
    decimals: 18,
    symbol: "DODO",
    logoURI:
      "https://images.dodoex.io/KEPetVp3nQkB6BZ_cb05trEfBbGxFEg92UnF8NZsEzM/rs:fit:96:96:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vVE5wTlRseEJGbFRDalM1ZFh3SWJ3Mm1hZ3NPUFFkZ3Z1UEhuZDZrNWdyMC9hSFIwY0hNNkx5OWpaRzR0YldWa2FXRXVaRzlrYjJWNExtbHZMMFJQUkU5Zk4yUTBaamxtTldOaU15OUVUMFJQWHpka05HWTVaalZqWWpNdWNHNW4ucG5n.webp",
  },
  {
    chainId: 137,
    address: "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    name: "Dai Stablecoin",
    decimals: 18,
    symbol: "DAI",
    logoURI:
      "https://images.dodoex.io/hdRkGay_hj0t1OzN5C3c30cmch26tJhHUSroffg7ciY/rs:fit:96:96:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vOHd3UndQOG9DTTdBcF9NOW11bGVOQWRqYjhBOXpvX2FYUE5pVTNBYUtzYy9hSFIwY0hNNkx5OWpaRzR0YldWa2FXRXVaRzlrYjJWNExtbHZMMlZ5WXpJd0wyaDBkSEJ6WDNKaGQxOW5hWFJvZFdKMWMyVnlZMjl1ZEdWdWRGOWpiMjFmZEhKMWMzUjNZV3hzWlhSZllYTnpaWFJ6WDIxaGMzUmxjbDlpYkc5amEyTm9ZV2x1YzE5bGRHaGxjbVYxYlY5aGMzTmxkSE5mTUhnMlgwSXhOelUwTnpSZlJUZzVNRGswWDBNME5GOUVZVGs0WWprMU5GOUZaV1JsWDBGRFh6UTVOVEkzTVdRd1gwWmZiRzluYjE4MFpXSXdNVE0zTVRZeExuQnVady5wbmc.webp",
  },
  {
    chainId: 137,
    address: "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    name: "Ether",
    decimals: 18,
    symbol: "ETH",
    logoURI:
      "https://images.dodoex.io/WAobi4MZtLSxB59wgpyNlXLI44ZwlBh55qiGS2wLSGU/rs:fit:96:96:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vSUVnUUx3TjRMU2dKRDJTM3RmSFlHcmxZbFZQNGlJeFcxeEZVOUwwUExRNC9hSFIwY0hNNkx5OWpaRzR0YldWa2FXRXVaRzlrYjJWNExtbHZMMlZ5WXpJd0wyaDBkSEJ6WDNKaGQxOW5hWFJvZFdKMWMyVnlZMjl1ZEdWdWRGOWpiMjFmZEhKMWMzUjNZV3hzWlhSZllYTnpaWFJ6WDIxaGMzUmxjbDlpYkc5amEyTm9ZV2x1YzE5bGRHaGxjbVYxYlY5aGMzTmxkSE5mTUhoZlF6QXlZV0ZmUVRNNVlqSXlNMTlHUlY4NFgwUXdYMEV3WlRWZlF6UmZSakkzWlY5QlJGODVNRGd6WDBNM05UWmZRMk15WDJ4dloyOWZZelk0WXprelpHRXlNQzV3Ym1jLnBuZw.webp",
  },
  {
    chainId: 137,
    address: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
    name: "USD Coin",
    decimals: 6,
    symbol: "USDC",
    logoURI:
      "https://images.dodoex.io/er3iAGv39hCC7F8AydW_AteD3hnZj9HI2XcAaruHeqA/rs:fit:96:96:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vVE9WUWtzN3JQWU5OZWdmNTRuNC1Za1NGTFYyLXVGZmlJRFVYem85aVMzOC9hSFIwY0hNNkx5OWpaRzR0YldWa2FXRXVaRzlrYjJWNExtbHZMMlZ5WXpJd0wyaDBkSEJ6WDNKaGQxOW5hWFJvZFdKMWMyVnlZMjl1ZEdWdWRGOWpiMjFmZEhKMWMzUjNZV3hzWlhSZllYTnpaWFJ6WDIxaGMzUmxjbDlpYkc5amEyTm9ZV2x1YzE5bGRHaGxjbVYxYlY5aGMzTmxkSE5mTUhoZlFUQmlPRFk1T1RGak5qSXhPR0l6Tm1NeFpERTVYMFEwWVRKbE9WOUZZakJqWDBVek5qQTJaVjlDTkRoZmJHOW5iMTlpTmpGbE5EVmtNbVF6TG5CdVp3LnBuZw.webp",
  },
  {
    chainId: 137,
    address: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    name: "Tether USD",
    decimals: 6,
    symbol: "USDT",
    logoURI:
      "https://images.dodoex.io/WeK6L9_RjWUfAsnMuOAdyXIZVE3JK_H3lZU5vk2m7Tg/rs:fit:96:96:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vTFJ0aEt6X2VrSXdmTEh6NnAycW5rSkRFeUk1RXZrODlXQ0J2VW43MUJLNC9hSFIwY0hNNkx5OWpaRzR0YldWa2FXRXVaRzlrYjJWNExtbHZMMlZ5WXpJd0wyaDBkSEJ6WDNKaGQxOW5hWFJvZFdKMWMyVnlZMjl1ZEdWdWRGOWpiMjFmZEhKMWMzUjNZV3hzWlhSZllYTnpaWFJ6WDIxaGMzUmxjbDlpYkc5amEyTm9ZV2x1YzE5bGRHaGxjbVYxYlY5aGMzTmxkSE5mTUhoa1gwRkRYekUzWDBZNU5UaGZSREpsWlRVeU0yRXlNakEyTWpBMk9UazBOVGszWDBNeE0xOUVPRE14WldNM1gyeHZaMjlmWXpCbU9XVTFaamxoTmk1d2JtYy5wbmc.webp",
  },
  {
    chainId: 137,
    address: "0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6",
    name: "Wrapped BTC",
    decimals: 8,
    symbol: "WBTC",
    logoURI:
      "https://images.dodoex.io/_KjvB9pCVqbIIv_UnQvRWpjySBHiFnuHNSXUm_52UZM/rs:fit:96:96:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vZDQ4OVYzTW1NMi1qaFJueEQ2Y3MyY1gydXdGS2tkRHpiRGZsbkZhTTJxOC9hSFIwY0hNNkx5OWpaRzR0YldWa2FXRXVaRzlrYjJWNExtbHZMMlZ5WXpJd0wyaDBkSEJ6WDNKaGQxOW5hWFJvZFdKMWMyVnlZMjl1ZEdWdWRGOWpiMjFmZEhKMWMzUjNZV3hzWlhSZllYTnpaWFJ6WDIxaGMzUmxjbDlpYkc5amEyTm9ZV2x1YzE5bGRHaGxjbVYxYlY5aGMzTmxkSE5mTUhneU1qWXdYMFpCUTE4MVgwVTFOVFF5WVRjM00xOUJZVFEwWmw5Q1gwTm1aVjlFWmpkZlF6RTVNMkpqTWw5RE5UazVYMnh2WjI5Zk5HTmpORE0zTlRneFppNXdibWMucG5n.webp",
  },
  {
    chainId: 137,
    address: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    name: "Wrapped MATIC",
    decimals: 18,
    symbol: "WMATIC",
    logoURI:
      "https://images.dodoex.io/HfZVSaAES3QRplSybW2uoKmYf6pVTRaxufn95_Qto_A/rs:fit:96:96:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vMERhZEZTUUVFX0d4YlBoMHY3Wk1Hb1FwaWtOQm1vaWFyZTFlaFdJbU44dy9hSFIwY0hNNkx5OWpaRzR0YldWa2FXRXVaRzlrYjJWNExtbHZMMjFoZEdsalgyVmlOekZtTkdFMU9XRXZiV0YwYVdOZlpXSTNNV1kwWVRVNVlTNTNaV0p3LndlYnA.webp",
  },
  {
    chainId: 42161,
    address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    name: "ETH",
    decimals: 18,
    symbol: "ETH",
    logoURI:
      "https://images.dodoex.io/KWYmKxU_OCS1PmzuEO0xjdr1Yn08SjGBkyqSI8hxlpE/rs:fit:96:96:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vdkkyZF9rdFdyUzQyM0FqZlBLbkxRYloyRGVzTkpacmNTSlZNb1dYRnkyOC9hSFIwY0hNNkx5OWpaRzR0YldWa2FXRXVaRzlrYjJWNExtbHZMMWRGVkVoZmFXTnZibDh6TVRjd1pqTTBORGcxTDFkRlZFaGZhV052Ymw4ek1UY3daak0wTkRnMUxuQnVady5wbmc.webp",
  },
  {
    chainId: 42161,
    address: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
    name: "Wrapped Ether",
    decimals: 18,
    symbol: "WETH",
    logoURI:
      "https://images.dodoex.io/6VmZGvbENd4TM1VK0rsYrfznRtG-GwxP-zCy2qyc9bM/rs:fit:96:96:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vWnJZUDB0ZHVTLUdDNE5FTThmRmV1RHhVWklPNUZpWnpqdkdBc0lfNlBQMC9hSFIwY0hNNkx5OWpaRzR0YldWa2FXRXVaRzlrYjJWNExtbHZMMWRGVkVoZmFXTnZibDh5TW1GbU4yVTVOVFJpTDFkRlZFaGZhV052Ymw4eU1tRm1OMlU1TlRSaUxuQnVady5wbmc.webp",
  },
  {
    chainId: 42161,
    address: "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
    name: "Dai Stablecoin",
    decimals: 18,
    symbol: "DAI",
    logoURI:
      "https://images.dodoex.io/XNbyKKzPthXfYOku9oXt5xzqM1JOWzxmbTLq2kkSvaU/rs:fit:96:96:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vWHprMUxKSzJ2V1BYVmN3TmVvVDRfR3NNdnBOVXFmTmliXzgybFZSVUNkWS9hSFIwY0hNNkx5OWpaRzR0YldWa2FXRXVaRzlrYjJWNExtbHZMMlZ5WXpJd0wyaDBkSEJ6WDNKaGQxOW5hWFJvZFdKMWMyVnlZMjl1ZEdWdWRGOWpiMjFmYzNWemFHbHpkMkZ3WDJsamIyNXpYMjFoYzNSbGNsOTBiMnRsYmw5a1lXbGZPRGhrT0dNeE5HRTBZaTVxY0djLmpwZw.webp",
  },
  {
    chainId: 42161,
    address: "0x69Eb4FA4a2fbd498C257C57Ea8b7655a2559A581",
    name: "DODO bird",
    decimals: 18,
    symbol: "DODO",
    logoURI:
      "https://images.dodoex.io/6U51l5aajoRrCaO6cpbrclQ2olWNDzuShggOCfoktCA/rs:fit:96:96:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8va3RkSlhwQ3dsQVZtZXNPb1dvQV95SG9IazBnV3UxUExienBKa1AxYnlJdy9hSFIwY0hNNkx5OWpaRzR0YldWa2FXRXVaRzlrYjJWNExtbHZMMFJQUkU5Zk5tTmtPVEV4WXprNE15OUVUMFJQWHpaalpEa3hNV001T0RNdWNHNW4ucG5n.webp",
  },
  {
    chainId: 42161,
    address: "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8",
    name: "USD Coin (Arb1)",
    decimals: 6,
    symbol: "USDC",
    logoURI:
      "https://images.dodoex.io/EaCi6KUTS-dinU-wGdG2IcundJ0fJfsOw97FRI2vaB8/rs:fit:96:96:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vdEVMaUx0Y1FaTVlBb3hkeExLdnRrZ2ltdVB5dmZRdUQ5U1BxU1c1bDZsMC9hSFIwY0hNNkx5OWpaRzR0YldWa2FXRXVaRzlrYjJWNExtbHZMMVZUUkVOZmFXTnZibDh5WlRFeU5tSTRaRFV5TDFWVFJFTmZhV052Ymw4eVpURXlObUk0WkRVeUxuQnVady5wbmc.webp",
  },
  {
    chainId: 42161,
    address: "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
    name: "Tether USD",
    decimals: 6,
    symbol: "USDT",
    logoURI:
      "https://images.dodoex.io/MGklrG5AImwUllGWDPMAHY0qLfdJQn4bj3iPFWSeohA/rs:fit:96:96:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vUEM3a3JPb252bWl4U3VRaFEwY29pVy1sczFYN25WS1lLNVluekQ2R2lxRS9hSFIwY0hNNkx5OWpaRzR0YldWa2FXRXVaRzlrYjJWNExtbHZMMVZUUkZSZlpUaGlOekZpTldZeU9TOVZVMFJVWDJVNFlqY3hZalZtTWprdWNHNW4ucG5n.webp",
  },
  {
    chainId: 42161,
    address: "0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f",
    name: "Wrapped BTC",
    decimals: 8,
    symbol: "WBTC",
    logoURI:
      "https://images.dodoex.io/n4n7ccBom2tgcZNN0jBv9YiTq5qGMjX6PHO0YGlpzZo/rs:fit:96:96:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vMElrSkQzVEc2SkFQdG1DV24tNnhfa0ozS3lXYXlVVHRhZ19PQmF0TFEwRS9hSFIwY0hNNkx5OWpaRzR0YldWa2FXRXVaRzlrYjJWNExtbHZMMWRDVkVOZmFXTnZibDgzWW1JelpUQTRaak14TDFkQ1ZFTmZhV052Ymw4M1ltSXpaVEE0WmpNeExuQnVady5wbmc.webp",
  },
  {
    chainId: 66,
    address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    name: "OKT",
    decimals: 18,
    symbol: "OKT",
    logoURI:
      "https://images.dodoex.io/pcltGNcIp7DP33nj3YxxBpuFEekNcOW3pwq9tjSrpY4/rs:fit:96:96:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vTzJHSDJYTndRancwbXMzQXNidG5xNzRkVEROVld3QWFOU1dXU2ZnU01QTS9hSFIwY0hNNkx5OXpkRzl5WVdkbExtZHZiMmRzWldGd2FYTXVZMjl0TDJSdlpHOHRiV1ZrYVdFdGMzUmhaMmx1Wnk5MWNHeHZZV1JmYVcxblh6RTVOalF5T1RCZk1qQXlNakExTWpBd056STNORFl3TWpJdWMzWm4uc3Zn.webp",
  },
  {
    chainId: 66,
    address: "0x382bB369d343125BfB2117af9c149795C6C65C50",
    name: "USDT",
    decimals: 18,
    symbol: "USDT",
    logoURI:
      "https://images.dodoex.io/MGklrG5AImwUllGWDPMAHY0qLfdJQn4bj3iPFWSeohA/rs:fit:96:96:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vUEM3a3JPb252bWl4U3VRaFEwY29pVy1sczFYN25WS1lLNVluekQ2R2lxRS9hSFIwY0hNNkx5OWpaRzR0YldWa2FXRXVaRzlrYjJWNExtbHZMMVZUUkZSZlpUaGlOekZpTldZeU9TOVZVMFJVWDJVNFlqY3hZalZtTWprdWNHNW4ucG5n.webp",
  },
  {
    chainId: 66,
    address: "0x218c3c3D49d0E7B37aff0D8bB079de36Ae61A4c0",
    name: "BNB",
    decimals: 18,
    symbol: "BNB",
    logoURI:
      "https://images.dodoex.io/BWNSMMqu68bSFJsr9vsiCV09IonH3yuAkR9gQxaipBw/rs:fit:96:96:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vZDhvazF4akd3bU9GUXUtMVdzNEVRLVBNRnJYMW9DLXlmYTc3OGJUUkx0US9hSFIwY0hNNkx5OWpaRzR0YldWa2FXRXVaRzlrYjJWNExtbHZMMEpPUWw5alpEUTVNalE0TXpJMkwwSk9RbDlqWkRRNU1qUTRNekkyTG5CdVp3LnBuZw.webp",
  },
  {
    chainId: 66,
    address: "0x54e4622DC504176b3BB432dCCAf504569699a7fF",
    name: "BTCK",
    decimals: 18,
    symbol: "BTCK",
    logoURI:
      "https://images.dodoex.io/L3kcNUKg94qnX8OPFlEvHKqWybnJD8SA9HTne3PW-Rg/rs:fit:96:96:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vNU1MQTB3Q3V6eEs4Q3ZMV0RqempuOVhzalBTWE1zbUpWZ0RRN1RPMG9FWS9hSFIwY0hNNkx5OWpaRzR0YldWa2FXRXVaRzlrYjJWNExtbHZMMEpVUTB0Zk1HWmlaRGRsTWpnNU5pOUNWRU5MWHpCbVltUTNaVEk0T1RZdWNHNW4ucG5n.webp",
  },
  {
    chainId: 66,
    address: "0x332730a4F6E03D9C55829435f10360E13cfA41Ff",
    name: "BUSD",
    decimals: 18,
    symbol: "BUSD",
    logoURI:
      "https://images.dodoex.io/-aEkA13gC6lOFoRyGeSy2BotE5YY3WUmcSlPa9Rpddc/rs:fit:96:96:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vaXVmSjhaNHFJYzlMMDgtNHpxRUMxWl94WVlqRkEtT3BqS1FJbmQyeHpHUS9hSFIwY0hNNkx5OWpaRzR0YldWa2FXRXVaRzlrYjJWNExtbHZMMEpWVTBSZk1XTTJNRFpqTjJZeE55OUNWVk5FWHpGak5qQTJZemRtTVRjdWNHNW4ucG5n.webp",
  },
  {
    chainId: 66,
    address: "0x21cDE7E32a6CAF4742d00d44B07279e7596d26B9",
    name: "DAIK",
    decimals: 18,
    symbol: "DAIK",
    logoURI:
      "https://images.dodoex.io/LOb-sXupURNN9HA0zBSfLfpZlx52N9qRSDUyzk3cg0A/rs:fit:96:96:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vck5pZzMtWU5TOUl4T3ZHX19taTFRSU9WYTZsczdZNXViRVhGcTJJVGplby9hSFIwY0hNNkx5OWpaRzR0YldWa2FXRXVaRzlrYjJWNExtbHZMMFJCU1V0Zk5ESTVNV1l4WlRoa1pTOUVRVWxMWHpReU9URm1NV1U0WkdVdWNHNW4ucG5n.webp",
  },
  {
    chainId: 66,
    address: "0xEF71CA2EE68F45B9Ad6F72fbdb33d707b872315C",
    name: "ETHK",
    decimals: 18,
    symbol: "ETHK",
    logoURI:
      "https://images.dodoex.io/IwBclhMMQLOo79RdRo6-HnUiXxshO_hSi02YK3L6MFI/rs:fit:96:96:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vcFBSd192aEx2MHh3WkRHU2k3eGYyYVJiQjlocVhfU2tacUdGc3RJR0pYRS9hSFIwY0hNNkx5OWpaRzR0YldWa2FXRXVaRzlrYjJWNExtbHZMMFZVU0V0Zk1UQTVNamMzTW1VMU9DOUZWRWhMWHpFd09USTNOekpsTlRndWNHNW4ucG5n.webp",
  },
  {
    chainId: 66,
    address: "0xc946DAf81b08146B1C7A8Da2A851Ddf2B3EAaf85",
    name: "USDC",
    decimals: 18,
    symbol: "USDC",
    logoURI:
      "https://images.dodoex.io/bXLFjIP5_obFZS9jvWA1bZc2b2Vsw4-tqcvj10aPUpU/rs:fit:96:96:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vcXhZT0hTcDYzemNTaDVHRVV1SFlya3JGS2s1MW9zLVUycG42bjNXV2pYay9hSFIwY0hNNkx5OWpaRzR0YldWa2FXRXVaRzlrYjJWNExtbHZMMVZUUkVOZk1UTTFZak13TkRkaVpDOVZVMFJEWHpFek5XSXpNRFEzWW1RdWNHNW4ucG5n.webp",
  },
  {
    chainId: 66,
    address: "0xdCAC52E001f5bd413aa6ea83956438F29098166b",
    name: "USDK",
    decimals: 18,
    symbol: "USDK",
    logoURI:
      "https://images.dodoex.io/X-_D75ZOpI-yC70WjdgC8wiqcssfRIALEv0eUfD3HiE/rs:fit:96:96:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vd290dHEzaWdkTDVzQlhSR2VFZkhQLTNpZHhDZzV0V3VGQmE3YjNrNUZITS9hSFIwY0hNNkx5OWpaRzR0YldWa2FXRXVaRzlrYjJWNExtbHZMMVZUUkV0Zk56aGpZekV6TW1OaVpDOVZVMFJMWHpjNFkyTXhNekpqWW1RdWNHNW4ucG5n.webp",
  },
  {
    chainId: 66,
    address: "0x506f731F7656e2FB34b587B912808f2a7aB640BD",
    name: "WBTCK",
    decimals: 18,
    symbol: "WBTCK",
    logoURI:
      "https://images.dodoex.io/7Gb1gYkvjWESf9ungWe4YKOrr5GY5UILmTwU_U95kCA/rs:fit:96:96:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vOTQ4NWZpU2NmUzBNc2V1YVZXWXo2Z1RuNVVidXJvZkxmN1VGSWhMdndjTS9hSFIwY0hNNkx5OWpaRzR0YldWa2FXRXVaRzlrYjJWNExtbHZMMWRDVkVOTFgyVXlZamRpWVRJNU16TXZWMEpVUTB0ZlpUSmlOMkpoTWprek15NXdibWMucG5n.webp",
  },
  {
    chainId: 1313161554,
    address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    name: "Ethereum",
    decimals: 18,
    symbol: "ETH",
    logoURI:
      "https://images.dodoex.io/WAobi4MZtLSxB59wgpyNlXLI44ZwlBh55qiGS2wLSGU/rs:fit:96:96:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vSUVnUUx3TjRMU2dKRDJTM3RmSFlHcmxZbFZQNGlJeFcxeEZVOUwwUExRNC9hSFIwY0hNNkx5OWpaRzR0YldWa2FXRXVaRzlrYjJWNExtbHZMMlZ5WXpJd0wyaDBkSEJ6WDNKaGQxOW5hWFJvZFdKMWMyVnlZMjl1ZEdWdWRGOWpiMjFmZEhKMWMzUjNZV3hzWlhSZllYTnpaWFJ6WDIxaGMzUmxjbDlpYkc5amEyTm9ZV2x1YzE5bGRHaGxjbVYxYlY5aGMzTmxkSE5mTUhoZlF6QXlZV0ZmUVRNNVlqSXlNMTlHUlY4NFgwUXdYMEV3WlRWZlF6UmZSakkzWlY5QlJGODVNRGd6WDBNM05UWmZRMk15WDJ4dloyOWZZelk0WXprelpHRXlNQzV3Ym1jLnBuZw.webp",
  },
  {
    chainId: 1313161554,
    address: "0xC9BdeEd33CD01541e1eeD10f90519d2C06Fe3feB",
    name: "Wrapped Ethereum",
    decimals: 18,
    symbol: "WETH",
    logoURI:
      "https://images.dodoex.io/KWYmKxU_OCS1PmzuEO0xjdr1Yn08SjGBkyqSI8hxlpE/rs:fit:96:96:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vdkkyZF9rdFdyUzQyM0FqZlBLbkxRYloyRGVzTkpacmNTSlZNb1dYRnkyOC9hSFIwY0hNNkx5OWpaRzR0YldWa2FXRXVaRzlrYjJWNExtbHZMMWRGVkVoZmFXTnZibDh6TVRjd1pqTTBORGcxTDFkRlZFaGZhV052Ymw4ek1UY3daak0wTkRnMUxuQnVady5wbmc.webp",
  },
  {
    chainId: 1313161554,
    address: "0x8BEc47865aDe3B172A928df8f990Bc7f2A3b9f79",
    name: "Aurora",
    decimals: 18,
    symbol: "Aurora",
    logoURI:
      "https://images.dodoex.io/2C2jfpO2-VYuwlga3SdtP5I6eJNJXIYq3LvPU4aFuiU/rs:fit:96:96:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vcHFWVWlJOEQ4VU5iUEZYRElvUnA3cFhEZFc4dmx4dWEtTmxubmxlam1PMC9hSFIwY0hNNkx5OWpaRzR0YldWa2FXRXVaRzlrYjJWNExtbHZMMkYxY205eVlWOHlOVEprWlRFeE56YzBMMkYxY205eVlWOHlOVEprWlRFeE56YzBMbmRsWW5BLndlYnA.webp",
  },
  {
    chainId: 1313161554,
    address: "0xe3520349F477A5F6EB06107066048508498A291b",
    name: "Dai Stablecoin",
    decimals: 18,
    symbol: "DAI",
    logoURI:
      "https://images.dodoex.io/XNbyKKzPthXfYOku9oXt5xzqM1JOWzxmbTLq2kkSvaU/rs:fit:96:96:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vWHprMUxKSzJ2V1BYVmN3TmVvVDRfR3NNdnBOVXFmTmliXzgybFZSVUNkWS9hSFIwY0hNNkx5OWpaRzR0YldWa2FXRXVaRzlrYjJWNExtbHZMMlZ5WXpJd0wyaDBkSEJ6WDNKaGQxOW5hWFJvZFdKMWMyVnlZMjl1ZEdWdWRGOWpiMjFmYzNWemFHbHpkMkZ3WDJsamIyNXpYMjFoYzNSbGNsOTBiMnRsYmw5a1lXbGZPRGhrT0dNeE5HRTBZaTVxY0djLmpwZw.webp",
  },
  {
    chainId: 1313161554,
    address: "0xe301eD8C7630C9678c39E4E45193D1e7Dfb914f7",
    name: "DODO Bird",
    decimals: 18,
    symbol: "DODO",
    logoURI:
      "https://images.dodoex.io/SEcMDWMmtHb3GFW5l7fTKYurpTqALHdZI3D6qzad1rs/rs:fit:96:96:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vQXRNMzllUjhRQzhYTl8tem5HU0pSLS1lV0tPUW91V3p2ci0zQ014WlJTSS9hSFIwY0hNNkx5OWpaRzR0YldWa2FXRXVaRzlrYjJWNExtbHZMekl3TUhneU1EQmZNVjh3WWpReFl6VTJZV1UxTHpJd01IZ3lNREJmTVY4d1lqUXhZelUyWVdVMUxuQnVady5wbmc.webp",
  },
  {
    chainId: 1313161554,
    address: "0xB12BFcA5A55806AaF64E99521918A4bf0fC40802",
    name: "USD Coin",
    decimals: 6,
    symbol: "USDC",
    logoURI:
      "https://images.dodoex.io/EaCi6KUTS-dinU-wGdG2IcundJ0fJfsOw97FRI2vaB8/rs:fit:96:96:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vdEVMaUx0Y1FaTVlBb3hkeExLdnRrZ2ltdVB5dmZRdUQ5U1BxU1c1bDZsMC9hSFIwY0hNNkx5OWpaRzR0YldWa2FXRXVaRzlrYjJWNExtbHZMMVZUUkVOZmFXTnZibDh5WlRFeU5tSTRaRFV5TDFWVFJFTmZhV052Ymw4eVpURXlObUk0WkRVeUxuQnVady5wbmc.webp",
  },
  {
    chainId: 1313161554,
    address: "0x4988a896b1227218e4A686fdE5EabdcAbd91571f",
    name: "Tether USD",
    decimals: 6,
    symbol: "USDT",
    logoURI:
      "https://images.dodoex.io/MGklrG5AImwUllGWDPMAHY0qLfdJQn4bj3iPFWSeohA/rs:fit:96:96:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vUEM3a3JPb252bWl4U3VRaFEwY29pVy1sczFYN25WS1lLNVluekQ2R2lxRS9hSFIwY0hNNkx5OWpaRzR0YldWa2FXRXVaRzlrYjJWNExtbHZMMVZUUkZSZlpUaGlOekZpTldZeU9TOVZVMFJVWDJVNFlqY3hZalZtTWprdWNHNW4ucG5n.webp",
  },
  {
    chainId: 1313161554,
    address: "0xF4eB217Ba2454613b15dBdea6e5f22276410e89e",
    name: "Wrapped BTC",
    decimals: 8,
    symbol: "WBTC",
    logoURI:
      "https://images.dodoex.io/n4n7ccBom2tgcZNN0jBv9YiTq5qGMjX6PHO0YGlpzZo/rs:fit:96:96:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vMElrSkQzVEc2SkFQdG1DV24tNnhfa0ozS3lXYXlVVHRhZ19PQmF0TFEwRS9hSFIwY0hNNkx5OWpaRzR0YldWa2FXRXVaRzlrYjJWNExtbHZMMWRDVkVOZmFXTnZibDgzWW1JelpUQTRaak14TDFkQ1ZFTmZhV052Ymw4M1ltSXpaVEE0WmpNeExuQnVady5wbmc.webp",
  },
  {
    chainId: 43114,
    address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    name: "Avalanche",
    decimals: 18,
    symbol: "AVAX",
    logoURI:
      "https://images.dodoex.io/yBkeC0v6hqQP-wNBxAq_C_D6JTYUc4Q86Wh6WIciESI/rs:fit:96:96:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vcmpNamFFdHJ5WUZxNks3TkpxYkRWczlEZF80aHp4ZUo3YnB0eGgtVERDYy9hSFIwY0hNNkx5OWpaRzR0YldWa2FXRXVaRzlrYjJWNExtbHZMMjEzWDI1ZlRqaHRNRjgwTURCNE5EQXdYems0WlRKaU5EQm1PRGN2YlhkZmJsOU9PRzB3WHpRd01IZzBNREJmT1RobE1tSTBNR1k0Tnk1cWNHYy5qcGc.webp",
  },
  {
    chainId: 43114,
    address: "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7",
    name: "Wrapped AVAX",
    decimals: 18,
    symbol: "WAVAX",
    logoURI:
      "https://images.dodoex.io/yBkeC0v6hqQP-wNBxAq_C_D6JTYUc4Q86Wh6WIciESI/rs:fit:96:96:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vcmpNamFFdHJ5WUZxNks3TkpxYkRWczlEZF80aHp4ZUo3YnB0eGgtVERDYy9hSFIwY0hNNkx5OWpaRzR0YldWa2FXRXVaRzlrYjJWNExtbHZMMjEzWDI1ZlRqaHRNRjgwTURCNE5EQXdYems0WlRKaU5EQm1PRGN2YlhkZmJsOU9PRzB3WHpRd01IZzBNREJmT1RobE1tSTBNR1k0Tnk1cWNHYy5qcGc.webp",
  },
  {
    chainId: 43114,
    address: "0x19860CCB0A68fd4213aB9D8266F7bBf05A8dDe98",
    name: "Binance USD",
    decimals: 18,
    symbol: "BUSD.e",
    logoURI:
      "https://images.dodoex.io/-aEkA13gC6lOFoRyGeSy2BotE5YY3WUmcSlPa9Rpddc/rs:fit:96:96:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vaXVmSjhaNHFJYzlMMDgtNHpxRUMxWl94WVlqRkEtT3BqS1FJbmQyeHpHUS9hSFIwY0hNNkx5OWpaRzR0YldWa2FXRXVaRzlrYjJWNExtbHZMMEpWVTBSZk1XTTJNRFpqTjJZeE55OUNWVk5FWHpGak5qQTJZemRtTVRjdWNHNW4ucG5n.webp",
  },
  {
    chainId: 43114,
    address: "0xd586E7F844cEa2F87f50152665BCbc2C279D8d70",
    name: "Dai Stablecoin",
    decimals: 18,
    symbol: "DAI.e",
    logoURI:
      "https://images.dodoex.io/XNbyKKzPthXfYOku9oXt5xzqM1JOWzxmbTLq2kkSvaU/rs:fit:96:96:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vWHprMUxKSzJ2V1BYVmN3TmVvVDRfR3NNdnBOVXFmTmliXzgybFZSVUNkWS9hSFIwY0hNNkx5OWpaRzR0YldWa2FXRXVaRzlrYjJWNExtbHZMMlZ5WXpJd0wyaDBkSEJ6WDNKaGQxOW5hWFJvZFdKMWMyVnlZMjl1ZEdWdWRGOWpiMjFmYzNWemFHbHpkMkZ3WDJsamIyNXpYMjFoYzNSbGNsOTBiMnRsYmw5a1lXbGZPRGhrT0dNeE5HRTBZaTVxY0djLmpwZw.webp",
  },
  {
    chainId: 43114,
    address: "0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664",
    name: "USD Coin",
    decimals: 6,
    symbol: "USDC.e",
    logoURI:
      "https://images.dodoex.io/bXLFjIP5_obFZS9jvWA1bZc2b2Vsw4-tqcvj10aPUpU/rs:fit:96:96:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vcXhZT0hTcDYzemNTaDVHRVV1SFlya3JGS2s1MW9zLVUycG42bjNXV2pYay9hSFIwY0hNNkx5OWpaRzR0YldWa2FXRXVaRzlrYjJWNExtbHZMMVZUUkVOZk1UTTFZak13TkRkaVpDOVZVMFJEWHpFek5XSXpNRFEzWW1RdWNHNW4ucG5n.webp",
  },
  {
    chainId: 43114,
    address: "0xc7198437980c041c805A1EDcbA50c1Ce5db95118",
    name: "Tether USD",
    decimals: 6,
    symbol: "USDT.e ",
    logoURI:
      "https://images.dodoex.io/MGklrG5AImwUllGWDPMAHY0qLfdJQn4bj3iPFWSeohA/rs:fit:96:96:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vUEM3a3JPb252bWl4U3VRaFEwY29pVy1sczFYN25WS1lLNVluekQ2R2lxRS9hSFIwY0hNNkx5OWpaRzR0YldWa2FXRXVaRzlrYjJWNExtbHZMMVZUUkZSZlpUaGlOekZpTldZeU9TOVZVMFJVWDJVNFlqY3hZalZtTWprdWNHNW4ucG5n.webp",
  },
  {
    chainId: 43114,
    address: "0x50b7545627a5162F82A992c33b87aDc75187B218",
    name: "Wrapped BTC",
    decimals: 8,
    symbol: "WBTC.e",
    logoURI:
      "https://images.dodoex.io/n4n7ccBom2tgcZNN0jBv9YiTq5qGMjX6PHO0YGlpzZo/rs:fit:96:96:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vMElrSkQzVEc2SkFQdG1DV24tNnhfa0ozS3lXYXlVVHRhZ19PQmF0TFEwRS9hSFIwY0hNNkx5OWpaRzR0YldWa2FXRXVaRzlrYjJWNExtbHZMMWRDVkVOZmFXTnZibDgzWW1JelpUQTRaak14TDFkQ1ZFTmZhV052Ymw4M1ltSXpaVEE0WmpNeExuQnVady5wbmc.webp",
  },
  {
    chainId: 43114,
    address: "0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB",
    name: "Wrapped Ether",
    decimals: 18,
    symbol: "WETH.e",
    logoURI:
      "https://images.dodoex.io/KWYmKxU_OCS1PmzuEO0xjdr1Yn08SjGBkyqSI8hxlpE/rs:fit:96:96:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vdkkyZF9rdFdyUzQyM0FqZlBLbkxRYloyRGVzTkpacmNTSlZNb1dYRnkyOC9hSFIwY0hNNkx5OWpaRzR0YldWa2FXRXVaRzlrYjJWNExtbHZMMWRGVkVoZmFXTnZibDh6TVRjd1pqTTBORGcxTDFkRlZFaGZhV052Ymw4ek1UY3daak0wTkRnMUxuQnVady5wbmc.webp",
  },
  {
    chainId: 10,
    address: "0x4200000000000000000000000000000000000006",
    name: "WETH",
    decimals: 18,
    symbol: "WETH",
    logoURI:
      "https://images.dodoex.io/f9SbZq39ynUZS5ueKvAwzLmKUfcZvYpKDaysT3dkcvM/rs:fit:96:96:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vc2NkVzJUWjladmRiX3BVSjZ3b2YzeHRvYk94YUw3bEV5RmhhemhJUFhlMC9hSFIwY0hNNkx5OXpkRzl5WVdkbExtZHZiMmRzWldGd2FYTXVZMjl0TDJSdlpHOHRiV1ZrYVdFdGMzUmhaMmx1Wnk5MWNHeHZZV1JmYVcxblh6RXdNamd3TkRaZk1qQXlNakEwTWpJd01UQXpORGc1TURVdWNHNW4ucG5n.webp",
  },
  {
    chainId: 10,
    address: "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
    name: "DAI",
    decimals: 18,
    symbol: "DAI",
    logoURI:
      "https://images.dodoex.io/04gwZiu2K_9fdb2zLwzsX1nONCbBWrZOvTyOoEFhx3g/rs:fit:96:96:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vaDJCcmdhUmZxUzBLZDBEOVpZbEUteEtNMVdzeFFtRzBEWVpKOGJCaWN4US9hSFIwY0hNNkx5OXpkRzl5WVdkbExtZHZiMmRzWldGd2FYTXVZMjl0TDJSdlpHOHRiV1ZrYVdFdGMzUmhaMmx1Wnk5MWNHeHZZV1JmYVcxblh6SXlNRFUwTVRsZk1qQXlNakEwTWpJd01UQTBNak00TlRVdWFuQm4uanBn.webp",
  },
  {
    chainId: 10,
    address: "0x7F5c764cBc14f9669B88837ca1490cCa17c31607",
    name: "USDC",
    decimals: 6,
    symbol: "USDC",
    logoURI:
      "https://images.dodoex.io/XbtxsPIscM6p5ClHbLO0JumFHA9U23eON8-MDh-Plkg/rs:fit:96:96:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vaG1nM3hRa1BUTUtkcUNFVnd0LXU4S2lwTW5od1JCdGhLYWxENW9vSWlTZy9hSFIwY0hNNkx5OXpkRzl5WVdkbExtZHZiMmRzWldGd2FYTXVZMjl0TDJSdlpHOHRiV1ZrYVdFdGMzUmhaMmx1Wnk5MWNHeHZZV1JmYVcxblh6UXlNRFEyTkRaZk1qQXlNakEwTWpJd01UQTFNekF4TmpVdWNHNW4ucG5n.webp",
  },
  {
    chainId: 10,
    address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    name: "ETH",
    decimals: 18,
    symbol: "ETH",
    logoURI:
      "https://images.dodoex.io/bcyoORJ04NyP1HhYUZ9RrTZ-MEOFeNnMcrr__JZ6Ruw/rs:fit:96:96:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vV1lHaU9IVFVnVWs4Q3Z3WmNyN0hWX2w4SlNrQjRVQUh1UXpzaWd4U3Nmay9hSFIwY0hNNkx5OXpkRzl5WVdkbExtZHZiMmRzWldGd2FYTXVZMjl0TDJSdlpHOHRiV1ZrYVdFdGMzUmhaMmx1Wnk5MWNHeHZZV1JmYVcxblh6VTVPREk0T1Y4eU1ESXlNRFF5TWpBeU1qTTBNelk0TlM1d2JtYy5wbmc.webp",
  },
  {
    decimals: 18,
    logoURI:
      "https://images.dodoex.io/Gbch2fMVm-itKQ-wYdO0BE9s_LVK-8fr2eoJxyjM_pY/rs:fit:160:160:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vOXZDZEdXMV9BQnM2VTA3RzNIcDd1VnRURnppdWJvQlJObVJyVEZLaXhEcy9hSFIwY0hNNkx5OXpkRzl5WVdkbExtZHZiMmRzWldGd2FYTXVZMjl0TDJSdlpHOHRiV1ZrYVdFdGMzUmhaMmx1Wnk5MWNHeHZZV1JmYVcxblh6RTVORFV4TXpoZk1qQXlNekEzTURNd056RTRNalkyT0RjdWFuQm4uanBn.webp",
    name: "Wrapped Conflux",
    address: "0x14b2D3bC65e74DAE1030EAFd8ac30c533c976A9b",
    symbol: "WCFX",
    chainId: 1030,
  },
  {
    decimals: 18,
    logoURI:
      "https://images.dodoex.io/74nSnlgHYl303M6PsFI2ItXIpPdEaN5W62bJk0xrm6g/rs:fit:160:160:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vRUw0TjJvclJYb2pibEZwa2pTZy1mVlZuVDhhMWZ6amloek43eGlSZFdmWS9hSFIwY0hNNkx5OXpkRzl5WVdkbExtZHZiMmRzWldGd2FYTXVZMjl0TDJSdlpHOHRiV1ZrYVdFdGMzUmhaMmx1Wnk5MWNHeHZZV1JmYVcxblh6ZzNNRGN4TXpOZk1qQXlNekEzTURNd056TTJNVGM0TXpndWNHNW4ucG5n.webp",
    name: "BNB",
    address: "0x94bd7A37d2cE24cC597E158fACaa8d601083ffeC",
    symbol: "BNB",
    chainId: 1030,
  },
  {
    decimals: 18,
    logoURI:
      "https://images.dodoex.io/Tzga6cVcC2NZzIAvdf812Bcl5zSn5mNomHQRJhkjaiI/rs:fit:160:160:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vVG5iZnNQSUVGWmFEeGkxSm9GUUd3blZSUUtrREkza3h2ekhuUnllTmUwYy9hSFIwY0hNNkx5OXpkRzl5WVdkbExtZHZiMmRzWldGd2FYTXVZMjl0TDJSdlpHOHRiV1ZrYVdFdGMzUmhaMmx1Wnk5MWNHeHZZV1JmYVcxblh6STNNVEkwTTE4eU1ESXpNRGN3TXpBM01qa3lOVEl6TVM1d2JtYy5wbmc.webp",
    name: "Ethereum Token",
    address: "0xa47f43DE2f9623aCb395CA4905746496D2014d57",
    symbol: "ETH",
    chainId: 1030,
  },
  {
    chainId: 1030,
    address: "0xfe97e85d13abd9c1c33384e796f10b73905637ce",
    name: "Tether USD",
    decimals: 18,
    symbol: "USDT",
    logoURI:
      "https://images.dodoex.io/n_bg2q8GR4MlobGgr4RYo4r2gS6om7hmtGABi6qoulQ/rs:fit:96:96:0/g:no/aHR0cHM6Ly9jbXAuZG9kb2V4LmlvL2lnTHA5WW1zMnY4em9jOEVEcHRBOWRFTi0xZHZ0V2RkRGVMWjZaVjh1OGMvcnM6Zml0OjE2MDoxNjA6MC9nOm5vL2FIUjBjSE02THk5cGJXRm5aUzF3Y205NGVTNWtiMlJ2WlhndWFXOHZhQzFxVkRnMVZWVTRUalpUT1dsVlZuSkpMVnBCU0hjd1dXaDBTSFJPUjNoNWJUZHVZa2QyVUZobmR5OWhTRkl3WTBoTk5reDVPWHBrUnpsNVdWZGtiRXh0WkhaaU1tUnpXbGRHZDJGWVRYVlpNamwwVERKU2RscEhPSFJpVjFacllWZEZkR016VW1oYU1teDFXbms1TVdOSGVIWlpWMUptWVZjeGJsaDZhekpPYWtVMVRsWTRlVTFFU1hwTlJHTjNUWHBCTTAxcVdYZFBSR2N5VDFNMWQySnRZeTV3Ym1jLndlYnA.webp",
  },
  {
    chainId: 1030,
    address: "0x1F545487c62e5ACfEa45dcAdd9c627361d1616D8",
    name: "Wrapped BTC",
    decimals: 18,
    symbol: "WBTC",
    logoURI:
      "https://images.dodoex.io/1e_VT8Ppoj0-PDkhFC58pxdegwGCdWBOa5JTPU-Ol2U/rs:fit:160:160:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vVU92VExkR1I4SzNHWnhISnA0aEVSN09teVdjdWdsNDBlU1hEbmJKWlJ2RS9hSFIwY0hNNkx5OXpkRzl5WVdkbExtZHZiMmRzWldGd2FYTXVZMjl0TDJSdlpHOHRiV1ZrYVdFdGMzUmhaMmx1Wnk5MWNHeHZZV1JmYVcxblh6a3hNVGd3TWpaZk1qQXlNekEzTURNd056TXhOVEUzT1RVdWNHNW4ucG5n.webp",
  },
  {
    decimals: 18,
    logoURI:
      "https://images.dodoex.io/Wr2Rsy3ec2m0qdAHY95E-niUfibhSvMC9j6I9a2jW0w/rs:fit:160:160:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vWWNqMXJhUEVTYTYxeVpfeDdNNktWOGdabmxpVjdELTRCaWRpcVNYRExCZy9hSFIwY0hNNkx5OXpkRzl5WVdkbExtZHZiMmRzWldGd2FYTXVZMjl0TDJSdlpHOHRiV1ZrYVdFdGMzUmhaMmx1Wnk5MWNHeHZZV1JmYVcxblh6TXlOVGs1TWpGZk1qQXlNekE0TURRd05ETTJNamM0TXpJdWFuQm4uanBn.webp",
    name: "Bald",
    address: "0x27D2DECb4bFC9C76F0309b8E88dec3a601Fe25a8",
    symbol: "BALD",
    chainId: 8453,
  },
  {
    decimals: 18,
    logoURI:
      "https://images.dodoex.io/20ZPKcy3KFBFeRhzXhM17Jn_ECPETHKMulANNxeUovo/rs:fit:160:160:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vZHV4b2JTNm03dzQyUWdfd09rZDBqV1JCWm5TNW5GQjRHM1A5Yk5wWDc3MC9hSFIwY0hNNkx5OXpkRzl5WVdkbExtZHZiMmRzWldGd2FYTXVZMjl0TDJSdlpHOHRiV1ZrYVdFdGMzUmhaMmx1Wnk5MWNHeHZZV1JmYVcxblh6SXhNVFk1TnpkZk1qQXlNekE0TURRd05ESTNNVEkyTmprdWMzWm4uc3Zn.webp",
    name: "Wrapped Ether",
    address: "0x4200000000000000000000000000000000000006",
    symbol: "WETH",
    chainId: 8453,
  },
  {
    decimals: 6,
    logoURI:
      "https://images.dodoex.io/XZ0_CC_Hy6CLGuNpjsfkgdGp67czyMKPcq8HcsabhyQ/rs:fit:160:160:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vbm93dE5BTjlkNEYzeDQwVEtlWFVwRTJtTEo4OEd1ZWo1RHplUFVLeWRQUS9hSFIwY0hNNkx5OXpkRzl5WVdkbExtZHZiMmRzWldGd2FYTXVZMjl0TDJSdlpHOHRiV1ZrYVdFdGMzUmhaMmx1Wnk5MWNHeHZZV1JmYVcxblh6WXdOemM0TkRaZk1qQXlNekE0TURRd05ETTRORGszTnprdWMzWm4uc3Zn.webp",
    name: "Axelar Wrapped USDC",
    address: "0xEB466342C4d449BC9f53A865D5Cb90586f405215",
    symbol: "axlUSDC",
    chainId: 8453,
  },
  {
    decimals: 18,
    logoURI:
      "https://maticnetwork.github.io/polygon-token-assets/assets/eth.svg",
    name: "Ethereum",
    address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    symbol: "ETH",
    chainId: 8453,
  },
  {
    decimals: 6,
    logoURI:
      "https://images.dodoex.io/UzsHCVfq_wYVDFVhkYthXWfHdldQUNx7LH3-3uXCs_U/rs:fit:96:96:0/g:no/aHR0cHM6Ly9yYXcuZ2l0aHVidXNlcmNvbnRlbnQuY29tL3RydXN0d2FsbGV0L2Fzc2V0cy9tYXN0ZXIvYmxvY2tjaGFpbnMvZXRoZXJldW0vYXNzZXRzLzB4QTBiODY5OTFjNjIxOGIzNmMxZDE5RDRhMmU5RWIwY0UzNjA2ZUI0OC9sb2dvLnBuZw.webp",
    name: "USD Base Coin",
    address: "0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA",
    symbol: "USDbC",
    chainId: 8453,
  },
  {
    decimals: 18,
    logoURI:
      "https://maticnetwork.github.io/polygon-token-assets/assets/eth.svg",
    name: "Ether",
    address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    symbol: "ETH",
    chainId: 59144,
  },
  {
    decimals: 18,
    logoURI:
      "https://maticnetwork.github.io/polygon-token-assets/assets/eth.svg",
    name: "Ether",
    address: "0xe5D7C2a44FfDDf6b295A15c148167daaAf5Cf34f",
    symbol: "WETH",
    chainId: 59144,
  },
  {
    decimals: 18,
    logoURI:
      "https://maticnetwork.github.io/polygon-token-assets/assets/eth.svg",
    name: "Ether",
    address: "0x5300000000000000000000000000000000000004",
    symbol: "WETH",
    chainId: 534352,
  },
  {
    decimals: 6,
    logoURI:
      "https://images.dodoex.io/XbtxsPIscM6p5ClHbLO0JumFHA9U23eON8-MDh-Plkg/rs:fit:96:96:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vaG1nM3hRa1BUTUtkcUNFVnd0LXU4S2lwTW5od1JCdGhLYWxENW9vSWlTZy9hSFIwY0hNNkx5OXpkRzl5WVdkbExtZHZiMmRzWldGd2FYTXVZMjl0TDJSdlpHOHRiV1ZrYVdFdGMzUmhaMmx1Wnk5MWNHeHZZV1JmYVcxblh6UXlNRFEyTkRaZk1qQXlNakEwTWpJd01UQTFNekF4TmpVdWNHNW4ucG5n.webp",
    name: "USD Coin",
    address: "0x06eFdBFf2a14a7c8E15944D1F4A48F9F95F663A4",
    symbol: "USDC",
    chainId: 534352,
  },
  {
    decimals: 18,
    logoURI:
      "https://maticnetwork.github.io/polygon-token-assets/assets/eth.svg",
    name: "Ether",
    address: "0x0Dc808adcE2099A9F62AA87D9670745AbA741746",
    symbol: "WETH",
    chainId: 169,
  },
  {
    decimals: 6,
    logoURI:
      "https://images.dodoex.io/XbtxsPIscM6p5ClHbLO0JumFHA9U23eON8-MDh-Plkg/rs:fit:96:96:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vaG1nM3hRa1BUTUtkcUNFVnd0LXU4S2lwTW5od1JCdGhLYWxENW9vSWlTZy9hSFIwY0hNNkx5OXpkRzl5WVdkbExtZHZiMmRzWldGd2FYTXVZMjl0TDJSdlpHOHRiV1ZrYVdFdGMzUmhaMmx1Wnk5MWNHeHZZV1JmYVcxblh6UXlNRFEyTkRaZk1qQXlNakEwTWpJd01UQTFNekF4TmpVdWNHNW4ucG5n.webp",
    name: "USD Coin",
    address: "0xb73603c5d87fa094b7314c74ace2e64d165016fb",
    symbol: "USDC",
    chainId: 169,
  },
  {
    decimals: 18,
    logoURI:
      "https://maticnetwork.github.io/polygon-token-assets/assets/eth.svg",
    name: "Ether",
    address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    symbol: "ETH",
    chainId: 169,
  },
  {
    decimals: 6,
    logoURI:
      "https://images.dodoex.io/MGklrG5AImwUllGWDPMAHY0qLfdJQn4bj3iPFWSeohA/rs:fit:96:96:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vUEM3a3JPb252bWl4U3VRaFEwY29pVy1sczFYN25WS1lLNVluekQ2R2lxRS9hSFIwY0hNNkx5OWpaRzR0YldWa2FXRXVaRzlrYjJWNExtbHZMMVZUUkZSZlpUaGlOekZpTldZeU9TOVZVMFJVWDJVNFlqY3hZalZtTWprdWNHNW4ucG5n.webp",
    name: "Tether USD",
    address: "0x201EBa5CC46D216Ce6DC03F6a759e8E766e956aE",
    symbol: "USDT",
    chainId: 5000,
  },
  {
    decimals: 6,
    logoURI:
      "https://images.dodoex.io/XbtxsPIscM6p5ClHbLO0JumFHA9U23eON8-MDh-Plkg/rs:fit:96:96:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vaG1nM3hRa1BUTUtkcUNFVnd0LXU4S2lwTW5od1JCdGhLYWxENW9vSWlTZy9hSFIwY0hNNkx5OXpkRzl5WVdkbExtZHZiMmRzWldGd2FYTXVZMjl0TDJSdlpHOHRiV1ZrYVdFdGMzUmhaMmx1Wnk5MWNHeHZZV1JmYVcxblh6UXlNRFEyTkRaZk1qQXlNakEwTWpJd01UQTFNekF4TmpVdWNHNW4ucG5n.webp",
    name: "USD Coin",
    address: "0x09bc4e0d864854c6afb6eb9a9cdf58ac190d0df9",
    symbol: "USDC",
    chainId: 5000,
  },
  {
    decimals: 18,
    logoURI:
      "https://maticnetwork.github.io/polygon-token-assets/assets/eth.svg",
    name: "Ether",
    address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    symbol: "ETH",
    chainId: 167000,
  },
  {
    decimals: 6,
    logoURI:
      "https://images.dodoex.io/XbtxsPIscM6p5ClHbLO0JumFHA9U23eON8-MDh-Plkg/rs:fit:96:96:0/g:no/aHR0cHM6Ly9pbWFnZS1wcm94eS5kb2RvZXguaW8vaG1nM3hRa1BUTUtkcUNFVnd0LXU4S2lwTW5od1JCdGhLYWxENW9vSWlTZy9hSFIwY0hNNkx5OXpkRzl5WVdkbExtZHZiMmRzWldGd2FYTXVZMjl0TDJSdlpHOHRiV1ZrYVdFdGMzUmhaMmx1Wnk5MWNHeHZZV1JmYVcxblh6UXlNRFEyTkRaZk1qQXlNakEwTWpJd01UQTFNekF4TmpVdWNHNW4ucG5n.webp",
    name: "USD Coin",
    address: "0x07d83526730c7438048d55a4fc0b850e2aab6f0b",
    symbol: "USDC",
    chainId: 167000,
  },
  {
    address: "0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82", // CAKE contract address
    symbol: "CAKE",
    name: "PancakeSwap Token",
    decimals: 18,
    chainId: 56, // Binance Smart Chain
    logoURI: "https://s2.coinmarketcap.com/static/img/coins/64x64/7186.png", // CAKE logo
  },
  {
    address: "0xe02df9e3e622debdd69fb838bb799e3f168902c5", // BAKE contract address
    symbol: "BAKE",
    name: "BakeryToken",
    decimals: 18,
    chainId: 56, // Binance Smart Chain
    logoURI: "https://s2.coinmarketcap.com/static/img/coins/64x64/7064.png", // BAKE logo
  },
  {
    address: "0xcf6bb5389c92bdda8a3747ddb454cb7a64626c63", // XVS contract address
    symbol: "XVS",
    name: "Venus",
    decimals: 18,
    chainId: 56, // Binance Smart Chain
    logoURI: "https://s2.coinmarketcap.com/static/img/coins/64x64/7288.png", // XVS logo
  },
];
