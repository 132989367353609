import "./index.scss";

import ExampleCoin from "../../assets/icons/example-coin-icon.svg";

const Market = () => {
  return (
    <section className="market-container">
      <div className="market-inner-container">
        <h1>Meteor Markets</h1>
        <div className="search-controls">
          <div className="search-bar">
            <input type="text" placeholder="Search" />
            <button>
              <i>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  ></path>
                </svg>
              </i>
            </button>
          </div>
          <div className="sort-by">
            <span>Sort by:</span>
            <div className="select-container">
              <select>
                <option value="option1">Starred</option>
              </select>
              <span>
                <svg
                  width="14"
                  height="8"
                  viewBox="0 0 14 8"
                  fill="#fff"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                >
                  <path d="M7.00023 7.71309L13.0102 1.70309L11.5972 0.288086L7.00023 4.88809L2.40423 0.288086L0.990234 1.70209L7.00023 7.71309Z"></path>
                </svg>
              </span>
            </div>
          </div>
        </div>
        <div className="market-cards">
          <div className="market-card">
            <div className="market-header">
              <div className="market-header-inner">
                <div>
                  <span className="market-title">sFRAX</span>
                  <span className="market-percentage">0.1%</span>
                  <i>
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="#415981"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.89952 14.7419L4.58536 17.3408C4.39477 17.4621 4.19552 17.5141 3.98761 17.4968C3.7797 17.4794 3.59778 17.4101 3.44184 17.2888C3.28591 17.1676 3.16463 17.0158 3.078 16.8335C2.99137 16.6512 2.97404 16.4478 3.02602 16.2233L4.16953 11.3114L0.349162 8.0108C0.175903 7.85486 0.0677886 7.6771 0.0248202 7.4775C-0.0181481 7.27791 -0.00532696 7.08317 0.0632838 6.89327C0.132588 6.70269 0.236543 6.54675 0.375151 6.42547C0.513758 6.30419 0.704344 6.22623 0.946907 6.19157L5.98876 5.74976L7.93793 1.12373C8.02456 0.915823 8.15901 0.759889 8.34127 0.655934C8.52354 0.551978 8.70962 0.5 8.89952 0.5C9.0901 0.5 9.27618 0.551978 9.45776 0.655934C9.63933 0.759889 9.77378 0.915823 9.8611 1.12373L11.8103 5.74976L16.8521 6.19157C17.0947 6.22623 17.2853 6.30419 17.4239 6.42547C17.5625 6.54675 17.6664 6.70269 17.7357 6.89327C17.805 7.08386 17.8182 7.27895 17.7752 7.47854C17.7323 7.67814 17.6238 7.85556 17.4499 8.0108L13.6295 11.3114L14.773 16.2233C14.825 16.4485 14.8077 16.6523 14.721 16.8346C14.6344 17.0168 14.5131 17.1683 14.3572 17.2888C14.2013 17.4101 14.0193 17.4794 13.8114 17.4968C13.6035 17.5141 13.4043 17.4621 13.2137 17.3408L8.89952 14.7419Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </i>
                  <p className="market-small-text">Frax</p>
                </div>
                <div>
                  <img src={ExampleCoin} alt="" />
                </div>
              </div>
            </div>

            <div className="market-body">
              <div className="market-body-section">
                <p className="section-title">Maturity</p>
                <p className="section-description">27 Mar 2025</p>
              </div>

              <div className="market-double-section">
                <div className="market-body-section">
                  <p className="section-title">Underlying APY</p>
                  <p className="section-description">5.386%</p>
                </div>

                <div>
                  <div className="market-body-section">
                    <p className="section-title price-title">Price</p>
                    <p className="section-description price-description">
                      $1.00
                    </p>
                  </div>
                </div>
              </div>

              <div className="market-body-section">
                <p className="section-title">Implied APY</p>
                <p className="section-description">5.551%</p>
              </div>

              <div className="apy-section">
                <div className="yt-card apy-card">
                  <span className="section-title">YT</span>

                  <div className="middle-section">
                    <span className="apy-title">Long Yield APY</span>
                    <span className="apy-price">Price</span>
                  </div>

                  <div className="price-section">
                    <span className="price-percentage">-13%</span>
                    <span className="price-section-price">$0.02</span>
                  </div>

                </div>

                <div className="pt-card apy-card">
                  <span className="section-title">PT</span>

                  <div className="middle-section">
                    <span className="apy-title">Fixed APY</span>
                    <span className="apy-price">Price</span>
                  </div>

                  <div className="price-section">
                    <span className="price-percentage">5.551%</span>
                    <span className="price-section-price">$0.98</span>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div className="market-card">
            <div className="market-header">
              <div className="market-header-inner">
                <div>
                  <span className="market-title">sFRAX</span>
                  <span className="market-percentage">0.1%</span>
                  <i>
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="#415981"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.89952 14.7419L4.58536 17.3408C4.39477 17.4621 4.19552 17.5141 3.98761 17.4968C3.7797 17.4794 3.59778 17.4101 3.44184 17.2888C3.28591 17.1676 3.16463 17.0158 3.078 16.8335C2.99137 16.6512 2.97404 16.4478 3.02602 16.2233L4.16953 11.3114L0.349162 8.0108C0.175903 7.85486 0.0677886 7.6771 0.0248202 7.4775C-0.0181481 7.27791 -0.00532696 7.08317 0.0632838 6.89327C0.132588 6.70269 0.236543 6.54675 0.375151 6.42547C0.513758 6.30419 0.704344 6.22623 0.946907 6.19157L5.98876 5.74976L7.93793 1.12373C8.02456 0.915823 8.15901 0.759889 8.34127 0.655934C8.52354 0.551978 8.70962 0.5 8.89952 0.5C9.0901 0.5 9.27618 0.551978 9.45776 0.655934C9.63933 0.759889 9.77378 0.915823 9.8611 1.12373L11.8103 5.74976L16.8521 6.19157C17.0947 6.22623 17.2853 6.30419 17.4239 6.42547C17.5625 6.54675 17.6664 6.70269 17.7357 6.89327C17.805 7.08386 17.8182 7.27895 17.7752 7.47854C17.7323 7.67814 17.6238 7.85556 17.4499 8.0108L13.6295 11.3114L14.773 16.2233C14.825 16.4485 14.8077 16.6523 14.721 16.8346C14.6344 17.0168 14.5131 17.1683 14.3572 17.2888C14.2013 17.4101 14.0193 17.4794 13.8114 17.4968C13.6035 17.5141 13.4043 17.4621 13.2137 17.3408L8.89952 14.7419Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </i>
                  <p className="market-small-text">Frax</p>
                </div>
                <div>
                  <img src={ExampleCoin} alt="" />
                </div>
              </div>
            </div>

            <div className="market-body">
              <div className="market-body-section">
                <p className="section-title">Maturity</p>
                <p className="section-description">27 Mar 2025</p>
              </div>

              <div className="market-double-section">
                <div className="market-body-section">
                  <p className="section-title">Underlying APY</p>
                  <p className="section-description">5.386%</p>
                </div>

                <div>
                  <div className="market-body-section">
                    <p className="section-title price-title">Price</p>
                    <p className="section-description price-description">
                      $1.00
                    </p>
                  </div>
                </div>
              </div>

              <div className="market-body-section">
                <p className="section-title">Implied APY</p>
                <p className="section-description">5.551%</p>
              </div>

              <div className="apy-section">
                <div className="yt-card apy-card">
                  <span className="section-title">YT</span>

                  <div className="middle-section">
                    <span className="apy-title">Long Yield APY</span>
                    <span className="apy-price">Price</span>
                  </div>

                  <div className="price-section">
                    <span className="price-percentage">-13%</span>
                    <span className="price-section-price">$0.02</span>
                  </div>

                </div>

                <div className="pt-card apy-card">
                  <span className="section-title">PT</span>

                  <div className="middle-section">
                    <span className="apy-title">Fixed APY</span>
                    <span className="apy-price">Price</span>
                  </div>

                  <div className="price-section">
                    <span className="price-percentage">5.551%</span>
                    <span className="price-section-price">$0.98</span>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div className="market-card">
            <div className="market-header">
              <div className="market-header-inner">
                <div>
                  <span className="market-title">sFRAX</span>
                  <span className="market-percentage">0.1%</span>
                  <i>
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="#415981"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.89952 14.7419L4.58536 17.3408C4.39477 17.4621 4.19552 17.5141 3.98761 17.4968C3.7797 17.4794 3.59778 17.4101 3.44184 17.2888C3.28591 17.1676 3.16463 17.0158 3.078 16.8335C2.99137 16.6512 2.97404 16.4478 3.02602 16.2233L4.16953 11.3114L0.349162 8.0108C0.175903 7.85486 0.0677886 7.6771 0.0248202 7.4775C-0.0181481 7.27791 -0.00532696 7.08317 0.0632838 6.89327C0.132588 6.70269 0.236543 6.54675 0.375151 6.42547C0.513758 6.30419 0.704344 6.22623 0.946907 6.19157L5.98876 5.74976L7.93793 1.12373C8.02456 0.915823 8.15901 0.759889 8.34127 0.655934C8.52354 0.551978 8.70962 0.5 8.89952 0.5C9.0901 0.5 9.27618 0.551978 9.45776 0.655934C9.63933 0.759889 9.77378 0.915823 9.8611 1.12373L11.8103 5.74976L16.8521 6.19157C17.0947 6.22623 17.2853 6.30419 17.4239 6.42547C17.5625 6.54675 17.6664 6.70269 17.7357 6.89327C17.805 7.08386 17.8182 7.27895 17.7752 7.47854C17.7323 7.67814 17.6238 7.85556 17.4499 8.0108L13.6295 11.3114L14.773 16.2233C14.825 16.4485 14.8077 16.6523 14.721 16.8346C14.6344 17.0168 14.5131 17.1683 14.3572 17.2888C14.2013 17.4101 14.0193 17.4794 13.8114 17.4968C13.6035 17.5141 13.4043 17.4621 13.2137 17.3408L8.89952 14.7419Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </i>
                  <p className="market-small-text">Frax</p>
                </div>
                <div>
                  <img src={ExampleCoin} alt="" />
                </div>
              </div>
            </div>

            <div className="market-body">
              <div className="market-body-section">
                <p className="section-title">Maturity</p>
                <p className="section-description">27 Mar 2025</p>
              </div>

              <div className="market-double-section">
                <div className="market-body-section">
                  <p className="section-title">Underlying APY</p>
                  <p className="section-description">5.386%</p>
                </div>

                <div>
                  <div className="market-body-section">
                    <p className="section-title price-title">Price</p>
                    <p className="section-description price-description">
                      $1.00
                    </p>
                  </div>
                </div>
              </div>

              <div className="market-body-section">
                <p className="section-title">Implied APY</p>
                <p className="section-description">5.551%</p>
              </div>

              <div className="apy-section">
                <div className="yt-card apy-card">
                  <span className="section-title">YT</span>

                  <div className="middle-section">
                    <span className="apy-title">Long Yield APY</span>
                    <span className="apy-price">Price</span>
                  </div>

                  <div className="price-section">
                    <span className="price-percentage">-13%</span>
                    <span className="price-section-price">$0.02</span>
                  </div>

                </div>

                <div className="pt-card apy-card">
                  <span className="section-title">PT</span>

                  <div className="middle-section">
                    <span className="apy-title">Fixed APY</span>
                    <span className="apy-price">Price</span>
                  </div>

                  <div className="price-section">
                    <span className="price-percentage">5.551%</span>
                    <span className="price-section-price">$0.98</span>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div className="market-card">
            <div className="market-header">
              <div className="market-header-inner">
                <div>
                  <span className="market-title">sFRAX</span>
                  <span className="market-percentage">0.1%</span>
                  <i>
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="#415981"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.89952 14.7419L4.58536 17.3408C4.39477 17.4621 4.19552 17.5141 3.98761 17.4968C3.7797 17.4794 3.59778 17.4101 3.44184 17.2888C3.28591 17.1676 3.16463 17.0158 3.078 16.8335C2.99137 16.6512 2.97404 16.4478 3.02602 16.2233L4.16953 11.3114L0.349162 8.0108C0.175903 7.85486 0.0677886 7.6771 0.0248202 7.4775C-0.0181481 7.27791 -0.00532696 7.08317 0.0632838 6.89327C0.132588 6.70269 0.236543 6.54675 0.375151 6.42547C0.513758 6.30419 0.704344 6.22623 0.946907 6.19157L5.98876 5.74976L7.93793 1.12373C8.02456 0.915823 8.15901 0.759889 8.34127 0.655934C8.52354 0.551978 8.70962 0.5 8.89952 0.5C9.0901 0.5 9.27618 0.551978 9.45776 0.655934C9.63933 0.759889 9.77378 0.915823 9.8611 1.12373L11.8103 5.74976L16.8521 6.19157C17.0947 6.22623 17.2853 6.30419 17.4239 6.42547C17.5625 6.54675 17.6664 6.70269 17.7357 6.89327C17.805 7.08386 17.8182 7.27895 17.7752 7.47854C17.7323 7.67814 17.6238 7.85556 17.4499 8.0108L13.6295 11.3114L14.773 16.2233C14.825 16.4485 14.8077 16.6523 14.721 16.8346C14.6344 17.0168 14.5131 17.1683 14.3572 17.2888C14.2013 17.4101 14.0193 17.4794 13.8114 17.4968C13.6035 17.5141 13.4043 17.4621 13.2137 17.3408L8.89952 14.7419Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </i>
                  <p className="market-small-text">Frax</p>
                </div>
                <div>
                  <img src={ExampleCoin} alt="" />
                </div>
              </div>
            </div>

            <div className="market-body">
              <div className="market-body-section">
                <p className="section-title">Maturity</p>
                <p className="section-description">27 Mar 2025</p>
              </div>

              <div className="market-double-section">
                <div className="market-body-section">
                  <p className="section-title">Underlying APY</p>
                  <p className="section-description">5.386%</p>
                </div>

                <div>
                  <div className="market-body-section">
                    <p className="section-title price-title">Price</p>
                    <p className="section-description price-description">
                      $1.00
                    </p>
                  </div>
                </div>
              </div>

              <div className="market-body-section">
                <p className="section-title">Implied APY</p>
                <p className="section-description">5.551%</p>
              </div>

              <div className="apy-section">
                <div className="yt-card apy-card">
                  <span className="section-title">YT</span>

                  <div className="middle-section">
                    <span className="apy-title">Long Yield APY</span>
                    <span className="apy-price">Price</span>
                  </div>

                  <div className="price-section">
                    <span className="price-percentage">-13%</span>
                    <span className="price-section-price">$0.02</span>
                  </div>

                </div>

                <div className="pt-card apy-card">
                  <span className="section-title">PT</span>

                  <div className="middle-section">
                    <span className="apy-title">Fixed APY</span>
                    <span className="apy-price">Price</span>
                  </div>

                  <div className="price-section">
                    <span className="price-percentage">5.551%</span>
                    <span className="price-section-price">$0.98</span>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div className="market-card">
            <div className="market-header">
              <div className="market-header-inner">
                <div>
                  <span className="market-title">sFRAX</span>
                  <span className="market-percentage">0.1%</span>
                  <i>
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="#415981"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.89952 14.7419L4.58536 17.3408C4.39477 17.4621 4.19552 17.5141 3.98761 17.4968C3.7797 17.4794 3.59778 17.4101 3.44184 17.2888C3.28591 17.1676 3.16463 17.0158 3.078 16.8335C2.99137 16.6512 2.97404 16.4478 3.02602 16.2233L4.16953 11.3114L0.349162 8.0108C0.175903 7.85486 0.0677886 7.6771 0.0248202 7.4775C-0.0181481 7.27791 -0.00532696 7.08317 0.0632838 6.89327C0.132588 6.70269 0.236543 6.54675 0.375151 6.42547C0.513758 6.30419 0.704344 6.22623 0.946907 6.19157L5.98876 5.74976L7.93793 1.12373C8.02456 0.915823 8.15901 0.759889 8.34127 0.655934C8.52354 0.551978 8.70962 0.5 8.89952 0.5C9.0901 0.5 9.27618 0.551978 9.45776 0.655934C9.63933 0.759889 9.77378 0.915823 9.8611 1.12373L11.8103 5.74976L16.8521 6.19157C17.0947 6.22623 17.2853 6.30419 17.4239 6.42547C17.5625 6.54675 17.6664 6.70269 17.7357 6.89327C17.805 7.08386 17.8182 7.27895 17.7752 7.47854C17.7323 7.67814 17.6238 7.85556 17.4499 8.0108L13.6295 11.3114L14.773 16.2233C14.825 16.4485 14.8077 16.6523 14.721 16.8346C14.6344 17.0168 14.5131 17.1683 14.3572 17.2888C14.2013 17.4101 14.0193 17.4794 13.8114 17.4968C13.6035 17.5141 13.4043 17.4621 13.2137 17.3408L8.89952 14.7419Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </i>
                  <p className="market-small-text">Frax</p>
                </div>
                <div>
                  <img src={ExampleCoin} alt="" />
                </div>
              </div>
            </div>

            <div className="market-body">
              <div className="market-body-section">
                <p className="section-title">Maturity</p>
                <p className="section-description">27 Mar 2025</p>
              </div>

              <div className="market-double-section">
                <div className="market-body-section">
                  <p className="section-title">Underlying APY</p>
                  <p className="section-description">5.386%</p>
                </div>

                <div>
                  <div className="market-body-section">
                    <p className="section-title price-title">Price</p>
                    <p className="section-description price-description">
                      $1.00
                    </p>
                  </div>
                </div>
              </div>

              <div className="market-body-section">
                <p className="section-title">Implied APY</p>
                <p className="section-description">5.551%</p>
              </div>

              <div className="apy-section">
                <div className="yt-card apy-card">
                  <span className="section-title">YT</span>

                  <div className="middle-section">
                    <span className="apy-title">Long Yield APY</span>
                    <span className="apy-price">Price</span>
                  </div>

                  <div className="price-section">
                    <span className="price-percentage">-13%</span>
                    <span className="price-section-price">$0.02</span>
                  </div>

                </div>

                <div className="pt-card apy-card">
                  <span className="section-title">PT</span>

                  <div className="middle-section">
                    <span className="apy-title">Fixed APY</span>
                    <span className="apy-price">Price</span>
                  </div>

                  <div className="price-section">
                    <span className="price-percentage">5.551%</span>
                    <span className="price-section-price">$0.98</span>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div className="market-card">
            <div className="market-header">
              <div className="market-header-inner">
                <div>
                  <span className="market-title">sFRAX</span>
                  <span className="market-percentage">0.1%</span>
                  <i>
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="#415981"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.89952 14.7419L4.58536 17.3408C4.39477 17.4621 4.19552 17.5141 3.98761 17.4968C3.7797 17.4794 3.59778 17.4101 3.44184 17.2888C3.28591 17.1676 3.16463 17.0158 3.078 16.8335C2.99137 16.6512 2.97404 16.4478 3.02602 16.2233L4.16953 11.3114L0.349162 8.0108C0.175903 7.85486 0.0677886 7.6771 0.0248202 7.4775C-0.0181481 7.27791 -0.00532696 7.08317 0.0632838 6.89327C0.132588 6.70269 0.236543 6.54675 0.375151 6.42547C0.513758 6.30419 0.704344 6.22623 0.946907 6.19157L5.98876 5.74976L7.93793 1.12373C8.02456 0.915823 8.15901 0.759889 8.34127 0.655934C8.52354 0.551978 8.70962 0.5 8.89952 0.5C9.0901 0.5 9.27618 0.551978 9.45776 0.655934C9.63933 0.759889 9.77378 0.915823 9.8611 1.12373L11.8103 5.74976L16.8521 6.19157C17.0947 6.22623 17.2853 6.30419 17.4239 6.42547C17.5625 6.54675 17.6664 6.70269 17.7357 6.89327C17.805 7.08386 17.8182 7.27895 17.7752 7.47854C17.7323 7.67814 17.6238 7.85556 17.4499 8.0108L13.6295 11.3114L14.773 16.2233C14.825 16.4485 14.8077 16.6523 14.721 16.8346C14.6344 17.0168 14.5131 17.1683 14.3572 17.2888C14.2013 17.4101 14.0193 17.4794 13.8114 17.4968C13.6035 17.5141 13.4043 17.4621 13.2137 17.3408L8.89952 14.7419Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </i>
                  <p className="market-small-text">Frax</p>
                </div>
                <div>
                  <img src={ExampleCoin} alt="" />
                </div>
              </div>
            </div>

            <div className="market-body">
              <div className="market-body-section">
                <p className="section-title">Maturity</p>
                <p className="section-description">27 Mar 2025</p>
              </div>

              <div className="market-double-section">
                <div className="market-body-section">
                  <p className="section-title">Underlying APY</p>
                  <p className="section-description">5.386%</p>
                </div>

                <div>
                  <div className="market-body-section">
                    <p className="section-title price-title">Price</p>
                    <p className="section-description price-description">
                      $1.00
                    </p>
                  </div>
                </div>
              </div>

              <div className="market-body-section">
                <p className="section-title">Implied APY</p>
                <p className="section-description">5.551%</p>
              </div>

              <div className="apy-section">
                <div className="yt-card apy-card">
                  <span className="section-title">YT</span>

                  <div className="middle-section">
                    <span className="apy-title">Long Yield APY</span>
                    <span className="apy-price">Price</span>
                  </div>

                  <div className="price-section">
                    <span className="price-percentage">-13%</span>
                    <span className="price-section-price">$0.02</span>
                  </div>

                </div>

                <div className="pt-card apy-card">
                  <span className="section-title">PT</span>

                  <div className="middle-section">
                    <span className="apy-title">Fixed APY</span>
                    <span className="apy-price">Price</span>
                  </div>

                  <div className="price-section">
                    <span className="price-percentage">5.551%</span>
                    <span className="price-section-price">$0.98</span>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div className="market-card">
            <div className="market-header">
              <div className="market-header-inner">
                <div>
                  <span className="market-title">sFRAX</span>
                  <span className="market-percentage">0.1%</span>
                  <i>
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="#415981"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.89952 14.7419L4.58536 17.3408C4.39477 17.4621 4.19552 17.5141 3.98761 17.4968C3.7797 17.4794 3.59778 17.4101 3.44184 17.2888C3.28591 17.1676 3.16463 17.0158 3.078 16.8335C2.99137 16.6512 2.97404 16.4478 3.02602 16.2233L4.16953 11.3114L0.349162 8.0108C0.175903 7.85486 0.0677886 7.6771 0.0248202 7.4775C-0.0181481 7.27791 -0.00532696 7.08317 0.0632838 6.89327C0.132588 6.70269 0.236543 6.54675 0.375151 6.42547C0.513758 6.30419 0.704344 6.22623 0.946907 6.19157L5.98876 5.74976L7.93793 1.12373C8.02456 0.915823 8.15901 0.759889 8.34127 0.655934C8.52354 0.551978 8.70962 0.5 8.89952 0.5C9.0901 0.5 9.27618 0.551978 9.45776 0.655934C9.63933 0.759889 9.77378 0.915823 9.8611 1.12373L11.8103 5.74976L16.8521 6.19157C17.0947 6.22623 17.2853 6.30419 17.4239 6.42547C17.5625 6.54675 17.6664 6.70269 17.7357 6.89327C17.805 7.08386 17.8182 7.27895 17.7752 7.47854C17.7323 7.67814 17.6238 7.85556 17.4499 8.0108L13.6295 11.3114L14.773 16.2233C14.825 16.4485 14.8077 16.6523 14.721 16.8346C14.6344 17.0168 14.5131 17.1683 14.3572 17.2888C14.2013 17.4101 14.0193 17.4794 13.8114 17.4968C13.6035 17.5141 13.4043 17.4621 13.2137 17.3408L8.89952 14.7419Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </i>
                  <p className="market-small-text">Frax</p>
                </div>
                <div>
                  <img src={ExampleCoin} alt="" />
                </div>
              </div>
            </div>

            <div className="market-body">
              <div className="market-body-section">
                <p className="section-title">Maturity</p>
                <p className="section-description">27 Mar 2025</p>
              </div>

              <div className="market-double-section">
                <div className="market-body-section">
                  <p className="section-title">Underlying APY</p>
                  <p className="section-description">5.386%</p>
                </div>

                <div>
                  <div className="market-body-section">
                    <p className="section-title price-title">Price</p>
                    <p className="section-description price-description">
                      $1.00
                    </p>
                  </div>
                </div>
              </div>

              <div className="market-body-section">
                <p className="section-title">Implied APY</p>
                <p className="section-description">5.551%</p>
              </div>

              <div className="apy-section">
                <div className="yt-card apy-card">
                  <span className="section-title">YT</span>

                  <div className="middle-section">
                    <span className="apy-title">Long Yield APY</span>
                    <span className="apy-price">Price</span>
                  </div>

                  <div className="price-section">
                    <span className="price-percentage">-13%</span>
                    <span className="price-section-price">$0.02</span>
                  </div>

                </div>

                <div className="pt-card apy-card">
                  <span className="section-title">PT</span>

                  <div className="middle-section">
                    <span className="apy-title">Fixed APY</span>
                    <span className="apy-price">Price</span>
                  </div>

                  <div className="price-section">
                    <span className="price-percentage">5.551%</span>
                    <span className="price-section-price">$0.98</span>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div className="market-card">
            <div className="market-header">
              <div className="market-header-inner">
                <div>
                  <span className="market-title">sFRAX</span>
                  <span className="market-percentage">0.1%</span>
                  <i>
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="#415981"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.89952 14.7419L4.58536 17.3408C4.39477 17.4621 4.19552 17.5141 3.98761 17.4968C3.7797 17.4794 3.59778 17.4101 3.44184 17.2888C3.28591 17.1676 3.16463 17.0158 3.078 16.8335C2.99137 16.6512 2.97404 16.4478 3.02602 16.2233L4.16953 11.3114L0.349162 8.0108C0.175903 7.85486 0.0677886 7.6771 0.0248202 7.4775C-0.0181481 7.27791 -0.00532696 7.08317 0.0632838 6.89327C0.132588 6.70269 0.236543 6.54675 0.375151 6.42547C0.513758 6.30419 0.704344 6.22623 0.946907 6.19157L5.98876 5.74976L7.93793 1.12373C8.02456 0.915823 8.15901 0.759889 8.34127 0.655934C8.52354 0.551978 8.70962 0.5 8.89952 0.5C9.0901 0.5 9.27618 0.551978 9.45776 0.655934C9.63933 0.759889 9.77378 0.915823 9.8611 1.12373L11.8103 5.74976L16.8521 6.19157C17.0947 6.22623 17.2853 6.30419 17.4239 6.42547C17.5625 6.54675 17.6664 6.70269 17.7357 6.89327C17.805 7.08386 17.8182 7.27895 17.7752 7.47854C17.7323 7.67814 17.6238 7.85556 17.4499 8.0108L13.6295 11.3114L14.773 16.2233C14.825 16.4485 14.8077 16.6523 14.721 16.8346C14.6344 17.0168 14.5131 17.1683 14.3572 17.2888C14.2013 17.4101 14.0193 17.4794 13.8114 17.4968C13.6035 17.5141 13.4043 17.4621 13.2137 17.3408L8.89952 14.7419Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </i>
                  <p className="market-small-text">Frax</p>
                </div>
                <div>
                  <img src={ExampleCoin} alt="" />
                </div>
              </div>
            </div>

            <div className="market-body">
              <div className="market-body-section">
                <p className="section-title">Maturity</p>
                <p className="section-description">27 Mar 2025</p>
              </div>

              <div className="market-double-section">
                <div className="market-body-section">
                  <p className="section-title">Underlying APY</p>
                  <p className="section-description">5.386%</p>
                </div>

                <div>
                  <div className="market-body-section">
                    <p className="section-title price-title">Price</p>
                    <p className="section-description price-description">
                      $1.00
                    </p>
                  </div>
                </div>
              </div>

              <div className="market-body-section">
                <p className="section-title">Implied APY</p>
                <p className="section-description">5.551%</p>
              </div>

              <div className="apy-section">
                <div className="yt-card apy-card">
                  <span className="section-title">YT</span>

                  <div className="middle-section">
                    <span className="apy-title">Long Yield APY</span>
                    <span className="apy-price">Price</span>
                  </div>

                  <div className="price-section">
                    <span className="price-percentage">-13%</span>
                    <span className="price-section-price">$0.02</span>
                  </div>

                </div>

                <div className="pt-card apy-card">
                  <span className="section-title">PT</span>

                  <div className="middle-section">
                    <span className="apy-title">Fixed APY</span>
                    <span className="apy-price">Price</span>
                  </div>

                  <div className="price-section">
                    <span className="price-percentage">5.551%</span>
                    <span className="price-section-price">$0.98</span>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div className="market-card">
            <div className="market-header">
              <div className="market-header-inner">
                <div>
                  <span className="market-title">sFRAX</span>
                  <span className="market-percentage">0.1%</span>
                  <i>
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="#415981"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.89952 14.7419L4.58536 17.3408C4.39477 17.4621 4.19552 17.5141 3.98761 17.4968C3.7797 17.4794 3.59778 17.4101 3.44184 17.2888C3.28591 17.1676 3.16463 17.0158 3.078 16.8335C2.99137 16.6512 2.97404 16.4478 3.02602 16.2233L4.16953 11.3114L0.349162 8.0108C0.175903 7.85486 0.0677886 7.6771 0.0248202 7.4775C-0.0181481 7.27791 -0.00532696 7.08317 0.0632838 6.89327C0.132588 6.70269 0.236543 6.54675 0.375151 6.42547C0.513758 6.30419 0.704344 6.22623 0.946907 6.19157L5.98876 5.74976L7.93793 1.12373C8.02456 0.915823 8.15901 0.759889 8.34127 0.655934C8.52354 0.551978 8.70962 0.5 8.89952 0.5C9.0901 0.5 9.27618 0.551978 9.45776 0.655934C9.63933 0.759889 9.77378 0.915823 9.8611 1.12373L11.8103 5.74976L16.8521 6.19157C17.0947 6.22623 17.2853 6.30419 17.4239 6.42547C17.5625 6.54675 17.6664 6.70269 17.7357 6.89327C17.805 7.08386 17.8182 7.27895 17.7752 7.47854C17.7323 7.67814 17.6238 7.85556 17.4499 8.0108L13.6295 11.3114L14.773 16.2233C14.825 16.4485 14.8077 16.6523 14.721 16.8346C14.6344 17.0168 14.5131 17.1683 14.3572 17.2888C14.2013 17.4101 14.0193 17.4794 13.8114 17.4968C13.6035 17.5141 13.4043 17.4621 13.2137 17.3408L8.89952 14.7419Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </i>
                  <p className="market-small-text">Frax</p>
                </div>
                <div>
                  <img src={ExampleCoin} alt="" />
                </div>
              </div>
            </div>

            <div className="market-body">
              <div className="market-body-section">
                <p className="section-title">Maturity</p>
                <p className="section-description">27 Mar 2025</p>
              </div>

              <div className="market-double-section">
                <div className="market-body-section">
                  <p className="section-title">Underlying APY</p>
                  <p className="section-description">5.386%</p>
                </div>

                <div>
                  <div className="market-body-section">
                    <p className="section-title price-title">Price</p>
                    <p className="section-description price-description">
                      $1.00
                    </p>
                  </div>
                </div>
              </div>

              <div className="market-body-section">
                <p className="section-title">Implied APY</p>
                <p className="section-description">5.551%</p>
              </div>

              <div className="apy-section">
                <div className="yt-card apy-card">
                  <span className="section-title">YT</span>

                  <div className="middle-section">
                    <span className="apy-title">Long Yield APY</span>
                    <span className="apy-price">Price</span>
                  </div>

                  <div className="price-section">
                    <span className="price-percentage">-13%</span>
                    <span className="price-section-price">$0.02</span>
                  </div>

                </div>

                <div className="pt-card apy-card">
                  <span className="section-title">PT</span>

                  <div className="middle-section">
                    <span className="apy-title">Fixed APY</span>
                    <span className="apy-price">Price</span>
                  </div>

                  <div className="price-section">
                    <span className="price-percentage">5.551%</span>
                    <span className="price-section-price">$0.98</span>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div className="market-card">
            <div className="market-header">
              <div className="market-header-inner">
                <div>
                  <span className="market-title">sFRAX</span>
                  <span className="market-percentage">0.1%</span>
                  <i>
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="#415981"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.89952 14.7419L4.58536 17.3408C4.39477 17.4621 4.19552 17.5141 3.98761 17.4968C3.7797 17.4794 3.59778 17.4101 3.44184 17.2888C3.28591 17.1676 3.16463 17.0158 3.078 16.8335C2.99137 16.6512 2.97404 16.4478 3.02602 16.2233L4.16953 11.3114L0.349162 8.0108C0.175903 7.85486 0.0677886 7.6771 0.0248202 7.4775C-0.0181481 7.27791 -0.00532696 7.08317 0.0632838 6.89327C0.132588 6.70269 0.236543 6.54675 0.375151 6.42547C0.513758 6.30419 0.704344 6.22623 0.946907 6.19157L5.98876 5.74976L7.93793 1.12373C8.02456 0.915823 8.15901 0.759889 8.34127 0.655934C8.52354 0.551978 8.70962 0.5 8.89952 0.5C9.0901 0.5 9.27618 0.551978 9.45776 0.655934C9.63933 0.759889 9.77378 0.915823 9.8611 1.12373L11.8103 5.74976L16.8521 6.19157C17.0947 6.22623 17.2853 6.30419 17.4239 6.42547C17.5625 6.54675 17.6664 6.70269 17.7357 6.89327C17.805 7.08386 17.8182 7.27895 17.7752 7.47854C17.7323 7.67814 17.6238 7.85556 17.4499 8.0108L13.6295 11.3114L14.773 16.2233C14.825 16.4485 14.8077 16.6523 14.721 16.8346C14.6344 17.0168 14.5131 17.1683 14.3572 17.2888C14.2013 17.4101 14.0193 17.4794 13.8114 17.4968C13.6035 17.5141 13.4043 17.4621 13.2137 17.3408L8.89952 14.7419Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </i>
                  <p className="market-small-text">Frax</p>
                </div>
                <div>
                  <img src={ExampleCoin} alt="" />
                </div>
              </div>
            </div>

            <div className="market-body">
              <div className="market-body-section">
                <p className="section-title">Maturity</p>
                <p className="section-description">27 Mar 2025</p>
              </div>

              <div className="market-double-section">
                <div className="market-body-section">
                  <p className="section-title">Underlying APY</p>
                  <p className="section-description">5.386%</p>
                </div>

                <div>
                  <div className="market-body-section">
                    <p className="section-title price-title">Price</p>
                    <p className="section-description price-description">
                      $1.00
                    </p>
                  </div>
                </div>
              </div>

              <div className="market-body-section">
                <p className="section-title">Implied APY</p>
                <p className="section-description">5.551%</p>
              </div>

              <div className="apy-section">
                <div className="yt-card apy-card">
                  <span className="section-title">YT</span>

                  <div className="middle-section">
                    <span className="apy-title">Long Yield APY</span>
                    <span className="apy-price">Price</span>
                  </div>

                  <div className="price-section">
                    <span className="price-percentage">-13%</span>
                    <span className="price-section-price">$0.02</span>
                  </div>

                </div>

                <div className="pt-card apy-card">
                  <span className="section-title">PT</span>

                  <div className="middle-section">
                    <span className="apy-title">Fixed APY</span>
                    <span className="apy-price">Price</span>
                  </div>

                  <div className="price-section">
                    <span className="price-percentage">5.551%</span>
                    <span className="price-section-price">$0.98</span>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Market;
