import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './index.scss';
import Market from './components/Market';
import Footer from './components/Footer';
import Header from './components/Header';
import Swapper from './components/Swapper';
import Bridge from './components/Bridge';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Router>
      <Header />
      <Routes>
        <Route path="*" element={<Navigate to="/swap" />} />
        <Route path="/swap" element={<Swapper />} />
        <Route path="/market" element={<Market />} />
        <Route path="/bridge" element={<Bridge />} />

      </Routes>
      <Footer />
    </Router>
  </React.StrictMode>
);