import "./index.scss";

const Footer = () => {
  return (
    <section className="footer-container">
      <footer>
        <div className="footer-inner-container">
          <div className="footer-media">
            <img
              src={require("../../assets/footer-logo.png")}
              alt=""
              className="company-logo"
            />
            <div className="media-icons">
              <a href="" className="media-redirect">
                <img
                  src={require("../../assets/twitter-logo.png")}
                  alt=""
                  className="media-icon"
                />
              </a>
              <a href="" className="media-redirect">
                <img
                  src={require("../../assets/telegram-logo.png")}
                  alt=""
                  className="media-icon"
                />
              </a>
              <a href="" className="media-redirect">
                <img
                  src={require("../../assets/discord-logo.png")}
                  alt=""
                  className="media-icon"
                />
              </a>
            </div>
          </div>

          <div className="columns-container">
            <ul>
              <li className="list-title">Community</li>
              <li>
                <a href="">Discord</a>
              </li>
              <li>
                <a href="">Telegram</a>
              </li>
              <li>
                <a href="">Twitter</a>
              </li>
            </ul>

            <ul>
              <li className="list-title">Protocol</li>
              <li>
                <a href="">Docs</a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </section>
  );
};

export default Footer;
