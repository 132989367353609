import { useState, useEffect } from "react";
import "./index.scss";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };


  return (
    <div className={`header-container visible ${menuOpen ? "open" : ""}`}>
      <div className="header-content-wrapper">
        <a href="" className="logo-container">
          <img
            src={require("../../assets/footer-logo.png")}
            loading="lazy"
            alt=""
            className="navigation-brand-img"
          />
        </a>
        <button
          className={`hamburger ${menuOpen ? "open" : ""}`}
          onClick={toggleMenu}
        >
          <div />
          <div />
          <div />
        </button>
        <article className={`header-navegation ${menuOpen ? "open" : ""}`}>
          <div className="menu-inner">
            <a href="/market" className="header-link">
              Market
            </a>
            <a href="/swap" className="header-link">
              Swap
            </a>
            <a href="/bridge" className="header-link">
              Bridge
            </a>
          </div>
        </article>
      </div>
    </div>
  );
};

export default Header;
