import React, { useState } from "react";
import "./index.scss";

const Bridge = () => {
  const [token, setToken] = useState("WETH");
  const [from, setFrom] = useState("Ethereum");
  const [to, setTo] = useState("BNB Chain");
  const [isTokenDropdownOpen, setIsTokenDropdownOpen] = useState(false);
  const [isFromDropdownOpen, setIsFromDropdownOpen] = useState(false);
  const [isToDropdownOpen, setIsToDropdownOpen] = useState(false);

  const handleTokenSelect = (token: string) => {
    setToken(token);
    setIsTokenDropdownOpen(false);
  };

  const handleFromSelect = (from: string) => {
    setFrom(from);
    setIsFromDropdownOpen(false);
  };

  const handleToSelect = (to: string) => {
    setTo(to);
    setIsToDropdownOpen(false);
  };

  return (
    <section className="bridge-container">
      <div className="bridge-card">
        <div className="bridge-header">
          <h2>Bridge</h2>
          <button className="settings-button">⚙️</button>
        </div>
        <div className="bridge-content">
          <div className="bridge-field">
            <label>Token</label>
            <div className="custom-dropdown custom-dropdown-full">
              <div
                className="custom-dropdown-selected"
                onClick={() => setIsTokenDropdownOpen(!isTokenDropdownOpen)}
              >
                <img
                  src={require("../../assets/icons/WETH.png")}
                  alt="WETH"
                  className="option-coin-icon"
                />
                {token}
                <span className="arrow">
                  <svg
                    width="14"
                    height="8"
                    viewBox="0 0 14 8"
                    fill="#fff"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                  >
                    <path d="M7.00023 7.71309L13.0102 1.70309L11.5972 0.288086L7.00023 4.88809L2.40423 0.288086L0.990234 1.70209L7.00023 7.71309Z"></path>
                  </svg>
                </span>
              </div>
              {isTokenDropdownOpen && (
                <div className="custom-dropdown-options">
                  <div
                    className="custom-dropdown-option"
                    onClick={() => handleTokenSelect("WETH")}
                  >
                    <img
                      src={require("../../assets/icons/WETH.png")}
                      alt="WETH"
                      className="option-coin-icon"
                    />
                    WETH
                  </div>
                  {/* Add more options as needed */}
                </div>
              )}
            </div>
          </div>
          <div className="bridge-field">
            <label>From</label>
            <div className="input-dropdown-wrapper">
              <div className="custom-dropdown">
                <div
                  className="custom-dropdown-selected"
                  onClick={() => setIsFromDropdownOpen(!isFromDropdownOpen)}
                >
                  <img
                    src={require("../../assets/icons/Ethereum.webp")}
                    alt="Ethereum"
                    className="option-coin-icon"
                  />
                  {from}
                  <span className="arrow">
                    <svg
                      width="14"
                      height="8"
                      viewBox="0 0 14 8"
                      fill="#fff"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                    >
                      <path d="M7.00023 7.71309L13.0102 1.70309L11.5972 0.288086L7.00023 4.88809L2.40423 0.288086L0.990234 1.70209L7.00023 7.71309Z"></path>
                    </svg>
                  </span>
                </div>
                {isFromDropdownOpen && (
                  <div className="custom-dropdown-options">
                    <div
                      className="custom-dropdown-option"
                      onClick={() => handleFromSelect("Ethereum")}
                    >
                      <img
                        src={require("../../assets/icons/Ethereum.webp")}
                        alt="Ethereum"
                        className="option-coin-icon"
                      />
                      Ethereum
                    </div>
                    {/* Add more options as needed */}
                  </div>
                )}
              </div>
              <input type="text" placeholder="0.00" />
            </div>
          </div>

          <div className="swap-icon">
            <button>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="#39afcd"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M7 16V4m0 0L3 8m4-4l4 4m6 0v12m0 0l4-4m-4 4l-4-4"
                ></path>
              </svg>
            </button>
          </div>

          <div className="bridge-field">
            <label>To</label>
            <div className="input-dropdown-wrapper">
              <div className="custom-dropdown">
                <div
                  className="custom-dropdown-selected"
                  onClick={() => setIsToDropdownOpen(!isToDropdownOpen)}
                >
                  <img
                    src={require("../../assets/icons/bnb.webp")}
                    alt="BNB Chain"
                    className="option-coin-icon"
                  />
                  {to}
                  <span className="arrow">
                    <svg
                      width="14"
                      height="8"
                      viewBox="0 0 14 8"
                      fill="#fff"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                    >
                      <path d="M7.00023 7.71309L13.0102 1.70309L11.5972 0.288086L7.00023 4.88809L2.40423 0.288086L0.990234 1.70209L7.00023 7.71309Z"></path>
                    </svg>
                  </span>
                </div>
                {isToDropdownOpen && (
                  <div className="custom-dropdown-options">
                    <div
                      className="custom-dropdown-option"
                      onClick={() => handleToSelect("BNB Chain")}
                    >
                      <img
                        src={require("../../assets/icons/bnb.webp")}
                        alt="BNB Chain"
                        className="option-coin-icon"
                      />
                      BNB Chain
                    </div>
                    {/* Add more options as needed */}
                  </div>
                )}
              </div>
              <input type="text" placeholder="0.00" />
            </div>
          </div>

          <div>
            <div className="bridge-rate">
              <p className="rate-title">Bridge Rate</p>
              <p className="rate-text">
                0 WETH on{" "}
                <img
                  src={require("../../assets/icons/Ethereum.webp")}
                  alt="Ethereum"
                  className="bridge-coin-icon"
                />
                {" = "}
                0 WETH on
                <img
                  src={require("../../assets/icons/bnb.webp")}
                  alt="BNB Chain"
                  className="bridge-coin-icon"
                />
              </p>
            </div>
            <div className="bridge-rate">
              <p className="rate-title">Estimated Gas</p>
              <p className="rate-text">$0.00</p>
            </div>
          </div>

          <div className="gas-images-traces">
            <img
              src={require("../../assets/icons/Ethereum.webp")}
              alt="Ethereum"
              className="bridge-coin-icon"
            />
            <div className="dashed-line"></div>
            <img
              src={require("../../assets/icons/bnb.webp")}
              alt="BNB Chain"
              className="bridge-coin-icon"
            />
          </div>
          <button className="connect-wallet-button">Connect Wallet</button>
        </div>
      </div>
    </section>
  );
};

export default Bridge;
