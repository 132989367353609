import { SwapWidget } from "@dodoex/widgets";
import tokenlist from "./tokenlist";
import "./index.scss";

function Swapper() {

  return (
    <section className="swapper-container">
      <div className="uniswap">
        <SwapWidget
          colorMode="dark"
          crossChain={true}
          tokenList={tokenlist} 
          defaultChainId={56}
          apikey="ef9apopzq9qrgntjubojbxe7hy4z5eez" // for default test
          defaultFromToken={{
            address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE", // BNB address
            symbol: "BNB",
            name: "Binance Coin",
            decimals: 18,
            chainId: 56,
            logoURI: '',
          }}
          defaultToToken={{
            chainId: 56,
            address: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
            name: 'Ether',
            decimals: 18,
            symbol: 'ETH',
            logoURI: '',
          }}
        />
      </div>
    </section>
  );
}

export default Swapper;